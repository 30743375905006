export const AIInboundOutboundStore = (set, get) => ({
    aiRules: [],
    setAiRules: (rules) => {
        set({ aiRules: rules });
    },
    selectedInboundGatewayId: null,
    setSelectedInboundGatewayId: (selectedInboundGatewayId) => set({ selectedInboundGatewayId }),
    getSelectedInboundGatewayId: () => {
        const { selectedInboundGatewayId } = get();
        return selectedInboundGatewayId;
    },
    selectedOutboundGatewayId: null,
    setSelectedOutboundGatewayId: (selectedOutboundGatewayId) => set({ selectedOutboundGatewayId }),
    getSelectedOutboundGatewayId: () => {
        const { selectedOutboundGatewayId } = get();
        return selectedOutboundGatewayId;
    },
    inboundList: [],
    setInboundList: (inboundList) => set({ inboundList }),
    getInBoundList: () => {
        const { inboundList } = get();
        return inboundList;
    },
    outboundList: [],
    setOutboundList: (outboundList) => set({ outboundList }),
    getOutboundList: () => {
        const { outboundList } = get();
        return outboundList;
    },
});


export const AIGatewayStore = (set, get) => ({
    modelsData: {},
    addModelData: (newData) => set((state) => ({
        modelsData: {
            ...state.modelsData,
            ...newData,
        },
    })),
    aiDicomNodes: [],
    setAiDicomNodes: (aiDicomNodes) => set({ aiDicomNodes }),
    getAiDicomNodes: () => {
        const { aiDicomNodes } = get();
        return aiDicomNodes;
    },
})

