import { persist } from 'zustand/middleware';

export const OnboardingStore = persist(
    (set) => ({
        hasCompletedOnboarding: false,
        setOnboardingComplete: () => set({ hasCompletedOnboarding: true }),
    }),
    {
        name: 'onboarding-storage',
        getStorage: () => sessionStorage, // Use sessionStorage to reset on reload
    }
)