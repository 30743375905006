import { DicomStore } from './dicom-node'
import { GatewayStore } from './gateway'
import { ImageTransferStudiesStore } from './studies'
import { SelectKeyStore, SelectKeyStoreStudiesExcel } from './rowsKey'
import { JobStore } from './jobs'
import { ExcelRawDataStore, ExcelReaderStore } from './excel'
import { create } from 'zustand'
import { AnonymousNameMapStore } from './anonymous'
import { ImageSharingLoadingJobs } from './status'
import { QueueJobs } from './jobs'
import { AIGatewayStore, AIInboundOutboundStore } from './ai'
import { OnboardingStore } from './onboard'
export const createImageSharingStore = create((...a) => ({
    ...DicomStore(...a),
    ...GatewayStore(...a),
    ...ImageTransferStudiesStore(...a),
    ...SelectKeyStore(...a),
    ...AnonymousNameMapStore(...a),
    ...JobStore(...a),
    ...ImageSharingLoadingJobs(...a),
    ...QueueJobs(...a),
    ...OnboardingStore(...a)
}), {
    name: `image-transfering-store`,
    getStorage: () => sessionStorage,
})

export const createBatchTrasnferingStore = create((...a) => ({
    ...DicomStore(...a),
    ...GatewayStore(...a),
    ...ImageTransferStudiesStore(...a),
    ...SelectKeyStoreStudiesExcel(...a),
    ...JobStore(...a),
    ...AnonymousNameMapStore(...a),
    ...ImageSharingLoadingJobs(...a),
    ...QueueJobs(...a),
    ...OnboardingStore(...a)
}), {
    name: `batch-transfering-store`,
    getStorage: () => sessionStorage,
})


export const createAIjobStore = create((...a) => ({
    ...GatewayStore(...a),
    ...AIGatewayStore(...a),
    ...ImageSharingLoadingJobs(...a),
    ...AIInboundOutboundStore(...a),
    ...OnboardingStore(...a)
}), {
    name: `ai-dicom-node-store`,
    getStorage: () => sessionStorage,
})

export const createExcelFileStore = create((...a) => ({
    ...ExcelRawDataStore(...a),
    ...ExcelReaderStore(...a),
    ...OnboardingStore(...a)
}), {
    name: `excel-raw-store`,
    getStorage: () => sessionStorage,
})