import React from 'react';
import { Select } from 'antd';

const SelectWithModal = ({ onSelectClick, label }) => {
    return (
        <div className='w-full h-full flex flex-col'>
            <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                <label className='text-xl font-bold'>{label}</label>
            </div>
            <div className='w-full h-full flex justify-center'>

                <Select
                    style={{ width: 310 }}
                    placeholder="Please select Gateway and DICOM Node"
                    onClick={onSelectClick}
                    open={false}
                >
                </Select>
            </div>
        </div>

    );
};

export default SelectWithModal;
