import lodash from 'lodash';

export const isOfflineStatus = (record) => {
    const status = lodash.get(record, "status", "offline");
    if (!status) {
        return true;
    }

    return status.indexOf('offline') >= 0;
}

export const isListenerOfflineStatus = (record) => {
    const status = lodash.get(record, "listener_status", "offline");
    if (!status) {
        return true;
    }
    
    return status.indexOf('offline') >= 0;
}

export const isJobStatusEligibleResend = (status) => {
    return ["job-success", "job-failure", "job_canceled"].includes(status);
}