import { Layout } from "antd";
import { Divider, Typography } from "antd";
const { Link } = Typography;

const Footer = () => {
  return (
    <>
      <Divider />
      <Layout.Footer className="bg-white">
        <ul className="flex">
          <li className="pr-5">
            <Link>Privacy</Link>
          </li>
          <li className="pr-5">
            <Link>Term of Usage</Link>
          </li>
          <li className="pr-5">
            <Link>Policy</Link>
          </li>
        </ul>
      </Layout.Footer>
    </>
  );
};
export default Footer;
