import logo from "./logo.png";

const Navbar = () => {
  return (
    <div className="flex-col items-center bg-white">
      <div className="flex flex-col">
        <div className="flex-grow flex">
          <img src={logo} alt="Your Logo" className="h-10" />
        </div>
      </div>
      <div className="border-b border-gray-300"></div>
    </div>
  );
};

export default Navbar;
