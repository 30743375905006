export const ImageTransferStudiesStore = (set) => ({
  currentSelectedSeries: [],
  currentSeriesData: [],
  currentStudyData: null,
  clearAllStudyData: () => set({ currentStudyData: null }),
  setStudyData: (newStudyData) => set((state) => ({
    currentStudyData: state.currentStudyData ? { ...state.currentStudyData, ...newStudyData } : newStudyData,
  })),
  currentImageSharingStudyData: [],
  setImageSharingStudyData: (currentImageSharingStudyData) => set({ currentImageSharingStudyData }),
  setSeriesData: (currentSeriesData) => set({ currentSeriesData }),
  setSelectedSeries: (currentSelectedSeries) => set({ currentSelectedSeries }),
})