export const ExcelReaderStore = (set) => ({
    fileList: [],
    setFileList: (file) => set((state) => {
        let fileList = state.fileList || [];
        fileList = [
            ...file
        ]
        return { fileList };
    }),
});

export const ExcelRawDataStore = (set, get) => ({
    excelRawData: [],
    setExcelRawData: (excelRawData) => set({ excelRawData }),
    excelTableData: [],
    setExcelTableData: (excelTableData) => set({ excelTableData }),
    studyWithSeriesData: {},
    setStudyWithSeriesData: (uniqueKey, newStudyWithSeriesData) => set((state) => ({
      studyWithSeriesData: {
        ...state.studyWithSeriesData,
        [uniqueKey]: newStudyWithSeriesData
      }
    }))
})
