import {
    fetchGatewayStatus,
    fetchListenerStatusById,
} from "../apis/gateway"
import {
    fetchAIListenerStatusById,
} from "../apis/ai"
import { CLOUD_PACS_MODE } from "../pages/constants/dicomNode";
import lodash from 'lodash';

const mappingData = (response, listenerStatus) => {
    return {
        gateway_ip: lodash.get(response, "data.ip", []).join(","),
        status: lodash.get(response, "data.status", "offline"),
        listener_status: lodash.get(listenerStatus, "data.status", "offline"),
        listener_ip: lodash.get(listenerStatus, "data.ip", []).join(";"),
        listener_name: lodash.get(
            listenerStatus,
            "data.listener.name",
            "N/A"
        ),
        listener_id: lodash.get(listenerStatus, "data.listener.id", "N/A"),
        listener_aet: lodash.get(listenerStatus, "data.listener.aet", "N/A"),
        listener_port: lodash.get(
            listenerStatus,
            "data.listener.port",
            "N/A"
        ),
        listener_transfersyntax: lodash.get(
            listenerStatus,
            "data.listener.transferSyntax",
            "N/A"
        ),
    };
}

export const fetchGatewaysStatus = async (gateways) => {
    try {
        const newGatewayDataWithStatus = gateways.map(async (gateway) => {
            if (gateway.id === CLOUD_PACS_MODE) {
                return {
                    ...gateway,
                };
            }

            const response = await fetchGatewayStatus(gateway.id);
            if (!response || !response.data.status) {
                return {
                    ...gateway,
                };
            }

            const listenerStatus = await fetchListenerStatusById(gateway.id);
            return{
                ...gateway,
                ...mappingData(response, listenerStatus)
            }
        });

        const allFetchedGatewayStatus = await Promise.all(newGatewayDataWithStatus);
        return allFetchedGatewayStatus;
    } catch (e) {
        console.error(e);
        return [];
    }
};


export const fetchGatewaysStatusAI = async (gateways) => {
    try {
        const newGatewayDataWithStatus = gateways.map(async (gateway) => {
            if (gateway.id === CLOUD_PACS_MODE) {
                return {
                    ...gateway,
                };
            }

            const response = await fetchGatewayStatus(gateway.id);
            if (!response || !response.data.status) {
                return {
                    ...gateway,
                };
            }

            const listenerStatus = await fetchAIListenerStatusById(gateway.id);
            return{
                ...gateway,
                ...mappingData(response, listenerStatus)
            }
        });

        const allFetchedGatewayStatus = await Promise.all(newGatewayDataWithStatus);
        return allFetchedGatewayStatus;
    } catch (e) {
        console.error(e);
        return [];
    }
};