import { Table } from "antd";
import { useEffect, useState } from "react";
import { findDataSeriesFromNode, buildQuery } from "../../../apis/node";
import { generateRowKey, studyColumns } from "./common";
import { useContext } from 'react'
import { AppContext } from '../../../libs/context'


export const StudiesListForExcel = ({
  data,
  onSelect,
  gatewayID,
  nodeID,
  uniqueKey,
}) => {
  const { store } = useContext(AppContext)
  const { toggleKeyExpand, selectedCBKeys, 
          addSelectedCBKey, deleteSelectedCBKey, setStoredStudiesMap, getStoredStudiesMap, expandedRowKeys,
          studyWithSeriesData,
          setStudyWithSeriesData
        } = store;

  const [isLoadingSeries, setIsLoadingSeries] = useState(false);

  const fetchAllTheSeries = async (updatedSelectedSeries) => {
    if (isLoadingSeries) return;
    setIsLoadingSeries(true);
    const updatedMapWithSeries = await Promise.all(updatedSelectedSeries.map(async (record) => {
      const response = await findDataSeriesFromNode(
        gatewayID,
        nodeID,
        buildQuery({
          studyInstanceUID: record.studyInstanceUID,
          type: "SERIES",
        })
      );
      if (response.data && response.data.length > 0) {
        const buildKeyForChildren = response.data.map((series) => {
          return {
            ...series,
            rowKey: generateRowKey(series),
          }
        });

        return {
          ...record,
          children: buildKeyForChildren,
        };
      }
      return record;
    }));

    setStudyWithSeriesData(uniqueKey, updatedMapWithSeries);
    setIsLoadingSeries(false);
  }

  const handleExpand = async (expanded, record) => {
    const currentKey = record.rowKey;
    toggleKeyExpand(currentKey);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const loadAllSeries = async () => {
        if (studyWithSeriesData[uniqueKey] && studyWithSeriesData[uniqueKey].length > 0) {
          return;
        }

        const updatedSelectedSeries = data.map((record) => {
          return {
            ...record,
            rowKey: generateRowKey(record),
          };
        });

        setStudyWithSeriesData(uniqueKey, updatedSelectedSeries);
        fetchAllTheSeries(updatedSelectedSeries);
      }

      loadAllSeries();
    }
  }, [data]);


  const expandAllSeriesAndStudies = (studyWithSeriesData) => {
    studyWithSeriesData.forEach((updatedSelectedSeries) => {
      if (!expandedRowKeys.includes(updatedSelectedSeries.rowKey)) {
        handleExpand(updatedSelectedSeries.rowKey, updatedSelectedSeries);
        addKeyRecursively(updatedSelectedSeries);
      }
    })
  }

  useEffect(() => {
    // auto load and example of how to use the expandedRowKeys
    const expandSeriesWhenLoaded = () => {
      const studySeries = studyWithSeriesData[uniqueKey]
      if (studySeries && studySeries.length > 0 && !isLoadingSeries) {
        expandAllSeriesAndStudies(studySeries);
      }
    }

    expandSeriesWhenLoaded();
  }, [isLoadingSeries])

  const updateSelectStudies = (rowsKeys) => {
    if (rowsKeys.length === 0) {
      onSelect([], uniqueKey);
      return;
    }
    const studies = rowsKeys.map(key => getStoredStudiesMap(key));
    onSelect(studies, uniqueKey);
  }

  const addKeyRecursively = (node) => {
    setStoredStudiesMap(node);
    addSelectedCBKey(uniqueKey, node.rowKey);
    if (node.children) {
      node.children.forEach((child) => addKeyRecursively(child));
    }
  };

  const removeKeyRecursively = (node) => {
    deleteSelectedCBKey(uniqueKey, node.rowKey);
    if (node.children) {
      node.children.forEach((child) => removeKeyRecursively(child));
    }
  };

  const rowSelection = {
    selectedRowKeys: Array.from(selectedCBKeys[uniqueKey] || []),
    onSelect: (record, selected, selectedRows) => {
      if (selected) {
        addKeyRecursively(record);
      } else {
        removeKeyRecursively(record);
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        changeRows.forEach((item) => addKeyRecursively(item));
      } else {
        changeRows.forEach((item) => removeKeyRecursively(item));
      }
    },
  };

  useEffect(() => {
    updateSelectStudies(Array.from(selectedCBKeys[uniqueKey] || []));
  }, [selectedCBKeys[uniqueKey]])

  return (
    <Table
      className="w-full border-solid border-[1px]"
      key={`table-${gatewayID}-${nodeID}`}
      pagination={true}
      rowSelection={rowSelection}
      dataSource={studyWithSeriesData[uniqueKey]}
      columns={studyColumns}
      rowKey="rowKey"
      expandable={{
        expandedRowKeys: expandedRowKeys,
        onExpand: handleExpand,
      }}
      style={{ width: "100%" }}
    />
  );
};
