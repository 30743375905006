import { Table } from "antd";
import { useEffect, useState } from "react";
import { findDataSeriesFromNode, buildQuery } from "../../../apis/node";
import { generateRowKey, studyColumns } from "./common";
import { useContext } from "react";
import { AppContext } from '../../../libs/context'

export const StudiesList = ({
  data,
  onSelect,
  gatewayID,
  nodeID
}) => {
  const [isLoadingSeries, setIsLoadingSeries] = useState(false);
  const { store } = useContext(AppContext)
  const { setSeriesData, currentSeriesData, selectedCBKeys, addSelectedCBKey, deleteSelectedCBKey, toggleKeyExpand, expandedRowKeys, setStoredStudiesMap, getStoredStudiesMap } = store;

  const fetchAllTheChildren = async (updatedSelectedSeries) => {
    if (!updatedSelectedSeries || updatedSelectedSeries.length === 0) return;
    if (isLoadingSeries) return;
    setIsLoadingSeries(true);
    const updatedMapWithSeries = await Promise.all(updatedSelectedSeries.map(async (record) => {
      const response = await findDataSeriesFromNode(
        gatewayID,
        nodeID,
        buildQuery({
          studyInstanceUID: record.studyInstanceUID,
          type: "SERIES",
        })
      );
      if (response.data && response.data.length > 0) {
        const buildKeyForChildren = response.data.map((series) => {
          return {
            ...series,
            studyDescription: record.description,
            rowKey: generateRowKey(series),
          }
        });

        return {
          ...record,
          children: buildKeyForChildren,
        };
      }
      return record;
    }));

    console.log("updatedMapWithSeries ", updatedMapWithSeries)
    setSeriesData(updatedMapWithSeries);
    setIsLoadingSeries(false);
  }

  const handleExpand = async (expanded, record) => {
    const currentKey = record.rowKey;
    toggleKeyExpand(currentKey);
  };

  useEffect(() => {
    const loadAllChildren = async () => {
      let updatedSelectedSeries = [];
      if (currentSeriesData.length > 0) {
        return setSeriesData(currentSeriesData);
      }

      if (data && currentSeriesData.length === 0) {
        updatedSelectedSeries = data.map((record) => {
          return {
            ...record,
            rowKey: generateRowKey(record),
          };
        });

        setSeriesData(updatedSelectedSeries);
        fetchAllTheChildren(updatedSelectedSeries);
      }
    }


    loadAllChildren()
  }, [data]);

  const updateSelectStudies = (rowsKeys) => {
    if (rowsKeys.length === 0) {
      onSelect([]);
      return;
    }
    const studies = rowsKeys.map(key => getStoredStudiesMap(key));
    onSelect(studies);
  }

  const addKeyRecursively = (node) => {
    setStoredStudiesMap(node);
    addSelectedCBKey(node.rowKey);
    if (node.children) {
      node.children.forEach((child) => addKeyRecursively(child));
    }
  };

  const removeKeyRecursively = (node) => {
    deleteSelectedCBKey(node.rowKey);
    if (node.children) {
      node.children.forEach((child) => removeKeyRecursively(child));
    }
  };

  const rowSelection = {
    selectedRowKeys: Array.from(selectedCBKeys),
    onSelect: (record, selected, selectedRows) => {
      if (selected) {
        addKeyRecursively(record);
      } else {
        removeKeyRecursively(record);
      }
      updateSelectStudies(Array.from(selectedCBKeys));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        changeRows.forEach((item) => addKeyRecursively(item));
      } else {
        changeRows.forEach((item) => removeKeyRecursively(item));
      }
      updateSelectStudies(Array.from(selectedCBKeys));
    },
  };

  useEffect(() => {
    updateSelectStudies(Array.from(selectedCBKeys));
  }, [selectedCBKeys])

  return (
    <Table
      key={`table-${gatewayID}-${nodeID}`}
      pagination={true}
      className="border border-solid" // Apply Tailwind CSS classes here
      rowSelection={rowSelection}
      dataSource={currentSeriesData}
      columns={studyColumns}
      rowKey='rowKey'
      expandable={{
        expandedRowKeys: expandedRowKeys,
        onExpand: handleExpand,
      }}
    />
  );
};
