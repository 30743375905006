import { Layout, Divider, message } from "antd";
import { ArrowDownOutlined } from '@ant-design/icons'
import { ExcelReader } from "../components/ExcelReader/";
import { useState, useEffect } from "react";
import { fetchGatewaysStatus } from '../../hooks/GatewayStatusCustomHook';
import { fetchDicomNodes } from '../../hooks/DicomNodeCustomHook';
import SelectWithModalWrapper from '../components/GatewayDicom/GatewayDicomSelectWrapper'
import {
  listAllGatewayByUserID,
} from "../../apis/gateway";
import {
  CLOUD_PACS_MODE,
  GATEWAY_ONLINE_STATUS,
} from "../constants/dicomNode";
import _ from "lodash";
import JobWrapper from '../components/Jobs/JobWrapper'
import { useContext } from 'react'
import { AppContext } from '../../libs/context'
import { ExcelList } from './ExcelList'

const CloudPacs = {
  id: CLOUD_PACS_MODE,
  name: "Cloud PACS System",
  status: GATEWAY_ONLINE_STATUS,
  listener_ip: "N/A",
  listener_status: "N/A",
  gateway_ip: "N/A",
};

export const BatchTransfering = () => {
  const { store } = useContext(AppContext)
  const { selectedSourceGatewayId, gatewayData,
    setGatewayData, destinationGateways,
    setDestinationGateways,
    setSelectedDestinationGateway,
    setExcelRawData,
    clearAllCBKeys,
    setExcelTableData,
    setSeriesData,
    setDestinationDicomNodes,
    setSelectedSeries,
    setSourceDicomNodes,
    selectedSourceDicomNodeId,
    selectedDestinationGatewayId,
    setStudyWithSeriesData,
    clearAllStudyData,
    setImageSharingStudyData,
    clearExpandedRowKeys } = store

  const [isLoadingGateway, setLoadingGateway] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const fetchGatewayAndStatus = async () => {
    try {
      setLoadingGateway(true);
      const response = await listAllGatewayByUserID();
      if (!response.data) return [];

      const gatewayWithStatus = await fetchGatewaysStatus(response.data);
      return gatewayWithStatus;
    } catch (e) {
      console.error(e);
      return [];
    } finally {
      setLoadingGateway(false);
    }
  }

  const fetchGateways = async (onDone) => {
    try {
      const gateways = await fetchGatewayAndStatus();
      if (gateways && gateways.length === 0) {
        return;
      }

      onDone({ gateways })
    } catch (e) {
      onDone({ gateways: [] })
    }
  }

  useEffect(() => {
    if (!gatewayData || gatewayData.length === 0) {
      fetchGateways((data) => {
        if (!data) return;
        setGatewayData(data.gateways);
      })
    } else {
      setGatewayData(gatewayData);
    }
  }, [])

  useEffect(() => {
    if (!destinationGateways || destinationGateways.length === 0) {
      fetchGateways((data) => {
        if (!data) return;
        setDestinationGateways(data.gateways);
        setSelectedDestinationGateway(CloudPacs);
      })
    } else {
      setDestinationGateways(destinationGateways);
    }
  }, [])


  const fetchDicomNode = async (currentSelectedGateway, onDone) => {
    try {
      if (!currentSelectedGateway) return;
      const data = await fetchDicomNodes(currentSelectedGateway);
      onDone(data);
    } catch (e) {
      onDone([]);
      messageApi.open({
        key: `gateway-dicome-listing-${currentSelectedGateway.id}`,
        type: 'error',
        content: e.message || 'failed to fetch dicom node',
      });
    }
  }

  useEffect(() => {
    const selectedSourceGateway = gatewayData && gatewayData.find((gateway) => gateway.id === selectedSourceGatewayId);
    fetchDicomNode(selectedSourceGateway, (data) => {
      setSourceDicomNodes(data);
    });

  }, [selectedSourceGatewayId])


  useEffect(() => {
    const selectedDestinatedGateway = destinationGateways && destinationGateways.find((gateway) => gateway.id === selectedDestinationGatewayId);
    fetchDicomNode(selectedDestinatedGateway, (data) => {
      setDestinationDicomNodes(data);
    });
  }, [selectedDestinationGatewayId])

  const refetchCurrentGatewayStatus = async (gateways) => {
    try {
      if (!gateways || gateways.length === 0) return;
      const gatewayWithStatus = await fetchGatewaysStatus(gateways);
      return gatewayWithStatus;
    } catch (e) {
      console.error(e);
      return [];
    }
  }


  // use fetch status existing gateway
  useEffect(() => {
    const fetchStatus = async () => {
      if (!gatewayData || gatewayData.length === 0) {
        return;
      }

      const gatewayWithStatus = await refetchCurrentGatewayStatus(gatewayData);
      setGatewayData(gatewayWithStatus);
    }

    fetchStatus();
  }, [selectedSourceGatewayId])

  const isSelectedCloudPacs = selectedDestinationGatewayId === CLOUD_PACS_MODE;
  // use fetch status existing gateway
  useEffect(() => {
    const fetchStatus = async () => {
      if (!destinationGateways || destinationGateways.length === 0 || isSelectedCloudPacs) {
        return;
      }

      const gatewayWithStatus = await refetchCurrentGatewayStatus(destinationGateways);
      setDestinationGateways(gatewayWithStatus);
    }

    fetchStatus();
  }, [selectedDestinationGatewayId])

  const onExcelFileReadFinish = (excelFileData) => {
    // clear all excel raw data and table
    setExcelRawData([]);
    setExcelTableData([]);
    // clear all options
    clearAllCBKeys();
    clearExpandedRowKeys();
    clearAllStudyData();

    // clear study data and series
    setImageSharingStudyData({})
    setSelectedSeries([])
    setStudyWithSeriesData({});
    // set new raw data table
    setExcelRawData(excelFileData);
  };

  const { setOnboardingComplete } = store;
  const { currentSelectedSeries, hasCompletedOnboarding, selectedDestinationDicomId } = store;
  const selectSourceGatewayAndDicomNode = selectedSourceGatewayId && selectedSourceDicomNodeId;
  const selectedDestinationGatewayAndDicom = (selectedDestinationGatewayId && selectedDestinationDicomId) || isSelectedCloudPacs
  const isSelectedSeries = currentSelectedSeries.length > 0;
  const isSelectedAllGatewayDicomSourceAndDestination = selectedDestinationGatewayAndDicom && selectSourceGatewayAndDicomNode

  const shouldShowStudiesList = isSelectedAllGatewayDicomSourceAndDestination || hasCompletedOnboarding;
  const shouldShowJob = isSelectedSeries || hasCompletedOnboarding;

  useEffect(() => {
    const setUserOnboard = () => {
      if (isSelectedSeries && isSelectedAllGatewayDicomSourceAndDestination) {
        setOnboardingComplete();
      }
    }
    setUserOnboard();
  }, [isSelectedAllGatewayDicomSourceAndDestination, isSelectedSeries])

  return (
    <Layout className="bg-white flex-row flex-col w-full">
      {contextHolder}
      <div className="p-4 mx-0 center mx-auto">
        <h1 className="text-2xl font-bold text-center">Batch Transfering</h1>
        <Divider />
        <SelectWithModalWrapper />
        <Divider />
        <div className="w-full py-3">
          <ExcelReader onExcelFileReadFinish={onExcelFileReadFinish} />
        </div>
        {shouldShowStudiesList &&
          <div className="w-full py-3">
            <div className="flex items-center justify-center h-full w-full">
              <ArrowDownOutlined style={{ fontSize: '24px' }} />
            </div>
            <ExcelList />
          </div>}
        {shouldShowJob &&
          <>
            <Divider />
            <JobWrapper />
          </>
        }

      </div>
    </Layout >
  );
};
