import { Input, Button, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState } from "react";

export const SearchGatewayFilter = ({
  label,
  isLoading,
  onSearchClick,
}) => {
  const [searchKeyWordResult, setSearchKeywordResult] = useState("");

  const onSearchCallBack = (e) => {
    setSearchKeywordResult(e.target.value);
  };

  return (
    <div className="flex w-full mb-4">
      {label && <p>{label}</p>}
      <div>
        <Input
          className="w-[150px]"
          value={searchKeyWordResult}
          placeholder="Search email..."
          data-testid="search-keyword-input"
          onChange={onSearchCallBack}
          onPressEnter={() => {
            if (searchKeyWordResult && searchKeyWordResult.length > 0) {
              onSearchClick(searchKeyWordResult);
            }
          }}
          prefix={<SearchOutlined />}
        />
        <Button
          onClick={() => {
            onSearchClick(searchKeyWordResult);
          }}
          className="ml-5"
          type="default"
          data-testid="search-dicom-image-button"
        >
          {isLoading && <Spin />}
          {!isLoading && "Find"}
        </Button>
      </div>
    </div>
  );
};
