import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useState } from 'react';
import jwtDecode from 'jwt-decode';
import AppLayout from './layouts/HomeLayout';
import DashboardLayout from './layouts/DashboardLayout';
import { LoginPage, SignupPage, ForgotPasswordPage } from './pages/Users';
import { DashboardPage, StudiesPage } from './pages/Dashboard';
import { BatchTransferingPage } from './pages/BatchTransfering';
import { AccessControlPage, AdminControlPage } from './pages/Permission';
import { AIPage } from './pages/AI';
import { AppContext } from './libs/context';
import { createImageSharingStore, createExcelFileStore, createBatchTrasnferingStore, createAIjobStore } from './stores/index'

// Simplifies route protection
const ProtectedRoute = ({ children }) => {
  return isValidJWT() ? children : <Navigate to="/dashboard/login" replace />;
};

const PublicRoute = ({ children }) => {
  return !isValidJWT() ? children : <Navigate to="/dashboard" replace />;
};

// Checks the validity of JWT token
function isValidJWT() {
  const token = localStorage.getItem('token');
  if (!token) return false;

  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp > Date.now() / 1000;
  } catch (error) {
    return false;
  }
}

// Layout wrapper for simplifying layout usage
const LayoutWrapper = ({ layout: Layout, store, component: Component, ...rest }) => {
  const [currentModal, setCurrentModal] = useState({
    modal: null,
    data: null,
  });


  return <AppContext.Provider value={{ currentModal, setCurrentModal, store }}>
    <Layout>
      <Component {...rest} />
    </Layout>
  </AppContext.Provider>
}

function App() {
  const imageSharingStore = createImageSharingStore();
  const batchTransferingStore = createBatchTrasnferingStore();
  const excelFileStore = createExcelFileStore();
  const aiJobStore = createAIjobStore();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<PublicRoute><Navigate to="/dashboard/login" replace /></PublicRoute>} />
        <Route path="/dashboard/login" element={<PublicRoute><LayoutWrapper component={LoginPage} layout={AppLayout} /></PublicRoute>} />
        <Route path="/dashboard/forgot" element={<PublicRoute><LayoutWrapper component={ForgotPasswordPage} layout={AppLayout} /></PublicRoute>} />
        <Route path="/dashboard/signup" element={<PublicRoute><LayoutWrapper component={SignupPage} layout={AppLayout} /></PublicRoute>} />
        <Route path="/dashboard" element={<ProtectedRoute><LayoutWrapper store={{ ...imageSharingStore }} component={DashboardPage} layout={DashboardLayout} /></ProtectedRoute>} />
        <Route path="/dashboard/studies" element={<ProtectedRoute><LayoutWrapper store={{ ...imageSharingStore }} component={StudiesPage} layout={DashboardLayout} /></ProtectedRoute>} />
        <Route path="/dashboard/batch-transfering" element={<ProtectedRoute><LayoutWrapper store={{ ...batchTransferingStore, ...excelFileStore  }} component={BatchTransferingPage} layout={DashboardLayout} /></ProtectedRoute>} />
        <Route path="/dashboard/permission" element={<ProtectedRoute><LayoutWrapper store={{ ...imageSharingStore  }} component={AccessControlPage} layout={DashboardLayout} /></ProtectedRoute>} />
        <Route path="/dashboard/admin-control" element={<ProtectedRoute><LayoutWrapper store={{ ...imageSharingStore  }} component={AdminControlPage} layout={DashboardLayout} /></ProtectedRoute>} />
        <Route path="/dashboard/ai" element={<ProtectedRoute><LayoutWrapper  store={{ ...aiJobStore }} component={AIPage} layout={DashboardLayout} /></ProtectedRoute>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
