const exportedConfig = {
  prod: {
    apiHost: "https://creni.org/api/v1/",
    uploaderApiHost: "https://creni.org/uploader",
    viewerHost: "https://creni.org/",
    sampleExcelLink: "https://creni.org/download-app/sample-file.xlsx",
    macOSLink: 'https://creni.org/download-app/gateway-1.0.0-x64-macos.dmg',
    windowLink: 'https://creni.org/download-app/gateway-x64-win32.zip',
    moreLink: "https://creni.org/download-app/"
  },
  stg: {
    uploaderApiHost: "http://192.168.192.16:7777",
    apiHost: "http://192.168.192.16:4500/v1/",
    sampleExcelLink: "http://192.168.192.16:9999/applications/sample-file.xlsx",
    viewerHost: "http://192.168.192.16:9999/",
    moreLink: "http://192.168.192.16:9999/applications/",
    macOSLink: 'http://192.168.192.16:9999/applications/gateway-1.0.0-x64.dmg',
    windowLink: 'https://creni.org/download-app/gateway-1.0.0-x64-win32.zip'
  },
  dev: {
    uploaderApiHost: "http://localhost:7777",
    apiHost: "http://localhost:4500/v1/",
    viewerHost: "http://localhost:8080/",
    sampleExcelLink: "http://192.168.192.16:9999/applications/sample-file.xlsx",
    moreLink: "http://192.168.192.16:9999/applications/",
    macOSLink: 'http://192.168.192.16:9999/applications/gateway-1.0.0-x64.dmg',
    windowLink: 'https://creni.org/download-app/gateway-1.0.0-x64-win32.zip'
  },
};

export const appConfig = exportedConfig[process.env["BUILD_MODE"]];
