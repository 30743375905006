import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Spin, Input, message } from "antd";
import { RetrieveNodeSelection, TransferSyntaxNode, DefaultRetrieveNode, DefaultTransferSyntax } from '../../components/DicomNodeSelection'
import { updateAiDicomNode } from '../../apis/ai'


export const EditDicomNodeModal = ({ onClose, data }) => {
    const [form] = Form.useForm();
    const [isAddingDicomNode, setAddingNewDicomNode] = useState(false);

    const onFinish = async () => {
        try {
            if (isAddingDicomNode) return;
            setAddingNewDicomNode(true);
            const values = await form.validateFields();
            await updateAiDicomNode({
                ...data,
                ...values
            });

            message.success("DICOM node updated successfully");
            onClose();

            setTimeout(() => {
                window.location.reload(true);
            }, 1000);
        } catch (e) {
            console.error(e);
            message.error("Failed to update dicom node");
        } finally {
            setAddingNewDicomNode(false);
        }
    }

    // init form data
    useEffect(() => {
        form.setFieldsValue({
            name: data.name,
            ip: data.ip,
            aet: data.aet,
            transferSyntax: data.transferSyntax,
            retrieve: data.retrieve,
            port: data.port,
        });
    }, []);

    return (
        <Modal
            title="Manage DICOM Node"
            open={true}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Button
                    className="bg-blue-500"
                    key="submit"
                    type="primary"
                    onClick={onFinish}
                >
                    Update Changes
                </Button>,
            ]}
            className="sm:max-w-7xl md:max-w-7xl"
            width={600}
        >
            <Spin spinning={isAddingDicomNode}>
                <Form initialValues={{ transferSyntax: DefaultTransferSyntax, retrieve: DefaultRetrieveNode }} form={form} layout="vertical" className="pt-2.5">
                    <Form.Item
                        rules={[{ required: true, message: "Please input node name" }]}
                        name="name"
                    >
                        <Input placeholder="Name"></Input>
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: "Please input IP" }]}
                        name="ip"
                    >
                        <Input placeholder="IP"></Input>
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: "Please input AET" }]}
                        name="aet"
                    >
                        <Input placeholder="AET"></Input>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            { required: true, message: "Please input transferSyntax" },
                        ]}
                        name="transferSyntax"
                    >
                        {TransferSyntaxNode({ isDisabled: false })}
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: "Please input Retrieve" }]}
                        name="retrieve"
                    >
                        {RetrieveNodeSelection({ isDisabled: false })}
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: "Please input port" }]}
                        name="port"
                    >
                        <Input placeholder="Port" />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};
