import { useState } from "react";
import { Layout, Divider, Tabs, Modal, Button } from "antd";
import { Gateways } from './pages/Gateway'
import { Inbound } from './pages/Inbound'
import { Outbound } from './pages/Outbound'
export const AIPage = () => {
    const [currentLog, setCurrentLog] = useState('');
    const [logModalVisible, setLogModalVisible] = useState(false);
    const items = [
        {
            key: 'ai-gateways',
            label: 'AI Gateways',
            children: <Gateways />,
        },
        {
            key: 'inbound',
            label: 'Inbound',
            children: <Inbound onViewLog={(message) => {
                setLogModalVisible(true);
                setCurrentLog(message);
            }} />,
        },
        {
            key: 'outbound',
            label: 'Outbound',
            children: <Outbound onViewLog={(message) => {
                setLogModalVisible(true);
                setCurrentLog(message);
            }} />,
        },
    ];

    return (
        <Layout className="bg-white flex-row flex-col w-full">
            <div className="p-4 max-w-screen-2xl mx-0 center mx-auto">
                <h1 className="text-2xl font-bold text-center mb-4">AI</h1>
                <Divider />
                <Tabs defaultActiveKey="ai-gateways" items={items} />
            </div>
            {logModalVisible && <Modal
                title="Job Logs"
                visible={logModalVisible}
                onCancel={() => setLogModalVisible(false)}
                footer={[
                    <Button key="close" onClick={() => setLogModalVisible(false)}>
                        Close
                    </Button>,
                ]}
            >
                <p>{currentLog}</p>
            </Modal>}
        </Layout>
    );
};
