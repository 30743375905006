import React, { useEffect, useState } from "react";
import { Tabs, Table, Spin } from "antd";
import { getPermissionByUsers } from "../../apis/apis";
import { listRequestPendingPermission } from "../../apis/admin";
const { TabPane } = Tabs;

const transformDataForCurrentPermission = (data) => {
  const groupedData = data.reduce((acc, current) => {
    const gatewayId = current.gateway_id;
    const dicomNodeId = current.dicomNode.id;

    // Unique key for each group
    const key = `${gatewayId}_${dicomNodeId}`;

    // If the key doesn't exist in the accumulator, create it
    if (!acc[key]) {
      acc[key] = {
        gateway_id: gatewayId,
        dicomNode_id: dicomNodeId,
        dicomNode: current.dicomNode,
        gateway: current.gateway,
        permissions: [],
      };
    }

    // Add the current permission to the permissions array
    acc[key].permissions.push(current.permission.name);

    return acc;
  }, {});

  // Convert the grouped data from an object back to an array
  const mergedData = Object.values(groupedData);
  return mergedData;
};

const transformPendingData = (data) => {
  return data.map(item => ({
    name: item.gateway.name,
    status: item.node.status,
    dicom_data: [
      {
        ...item.node,
        permissions: item.permissions,
      },
    ],
  }));
};

export const MyAccess = () => {
  const [loading, setLoading] = useState(false);
  const [pendingLoading, setPendingLoading] = useState(false);
  const dicomNodeColumns = [
    { title: "Node Name", dataIndex: "name", key: "name" },
    {
      title: "Permission",
      dataIndex: "permission",
      key: "permission",
      render: (_, record) => {
        if (record.permissions && record.permissions.length >= 0) {
          const joinedPermission = record.permissions.reduce((acc, current) => {
            acc = [...acc, current.name];
            return acc;
          }, []);
          return joinedPermission.join(" , ");
        }

        return <>N/A</>;
      },
    },
  ];

  const gatewayColumns = [
    { title: "Gateway Name", dataIndex: "name", key: "name" },
    {
      title: "Status", dataIndex: "status", key: "status", render: () => {
        return 'Pending'
      }
    },
  ];

  const expandedRowRenderForDicomNodes = (record) => (
    <Table
      columns={dicomNodeColumns}
      dataSource={record.dicom_data}
      pagination={false}
    />
  );

  const [permission, setPermission] = useState([]);

  useEffect(() => {
    const loadPermission = async () => {
      if (loading) {
        return;
      }
      setLoading(true);

      try {
        const response = await getPermissionByUsers();
        if (response.data && response.data.length > 0) {
          const data = transformDataForCurrentPermission(response.data);
          setPermission(data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    loadPermission();
  }, []);

  const [pendingPermission, setPendingPermission] = useState([]);

  useEffect(() => {
    const loadPendingPermission = async () => {
      if (pendingLoading) {
        return;
      }
      setPendingLoading(true);

      try {
        const response = await listRequestPendingPermission();
        if (response.data && response.data.length > 0) {
          setPendingPermission(transformPendingData(response.data));
        }
      } catch (e) {
        console.error(e);
      } finally {
        setPendingLoading(false);
      }
    };

    loadPendingPermission();
  }, []);

  const myCurrentAccessPermissionColumns = [
    {
      title: "Gateway Name", dataIndex: "name", key: "name", render: (_, record) => {
        return record.gateway.name;
      }
    },
    {
      title: "DICOM Node", dataIndex: "dicomNode", key: "dicomNode", render: (_, record) => {
        return record.dicomNode.name;
      }
    },
    {
      title: "IP", dataIndex: "ip", key: "ip", render: (_, record) => {
        return record.dicomNode.ip;
      }
    },
    {
      title: "Port", dataIndex: "port", key: "port", render: (_, record) => {
        return record.dicomNode.port;
      }
    },
    {
      title: "AET", dataIndex: "aet", key: "aet", render: (_, record) => {
        return record.dicomNode.aet;
      }
    },
    {
      title: "Permission", dataIndex: "permission", key: "permission", render: (_, record) => {
        return record.permissions.join(", ");
      }
    },
  ];

  return (
    <Spin spinning={loading || pendingLoading}>
      <Tabs
        defaultActiveKey="active-permission">
        <TabPane tab="Active" key="active-permission">
          <p className="text-sm text-gray-600 mb-2.5">Note: Gateways and DICOM Nodes are accessible to you</p>
          <div className="border-solid border-[1px]">
            <Table
              columns={myCurrentAccessPermissionColumns}
              dataSource={permission}
              pagination={false}
              rowKey="id"
            />
          </div>

        </TabPane>
        <TabPane
          tab="Request" key="pending-permission">
          <p className="text-sm text-gray-600 mb-2.5">Note: Your pending requests are waiting to approve by admin</p>
          <div className="border-solid border-[1px]">
            <Table
              columns={gatewayColumns}
              dataSource={pendingPermission}
              pagination={false}
              expandable={{ expandedRowRender: expandedRowRenderForDicomNodes }}
              rowKey="id"
            />
          </div>
        </TabPane>
      </Tabs>
    </Spin>
  );
};
