import { serverAPIAxios, _generateAuthoriseHeader } from './init'

export const buildQuery = ({
  patientName,
  studyInstanceUID,
  studyDescription,
  seriesDescription,
  seriesUID,
  type,
  modality,
  gender,
  studyDate,
  accessionNumber,
  patientID,
  referringPhysicianName,
  birthday,
  acquisitionTime,
  acquisitionDate,
  acquisitionNumber,
  retrieveAE,
  numOfSeriesRelated,
  code,
}) => {
  return [
    {
      key: "00100010", // Patient's Name
      value: patientName || "",
    },
    {
      key: "0020000D", // Study Instance UID
      value: studyInstanceUID || "",
    },
    {
      key: "0020000E", // Series Instance UID
      value: seriesUID || "",
    },
    {
      key: "00100020", // PatientID
      value: patientID || "",
    },
    {
      key: "00081030", //	Study Description
      value: studyDescription || "",
    },
    {
      key: "0008103e", //	Series Description
      value: seriesDescription || "",
    },
    {
      key: "00080100", //	Code
      value: code || "",
    },
    {
      key: "00080020", // Study Date
      value: studyDate || "",
    },
    {
      key: "00100030", // Patient's Birth Date
      value: birthday || "",
    },
    {
      key: "00100040", // Patient's Sex
      value: gender || "",
    },
    {
      key: "00080060", // 	Modality
      value: modality || "",
    },
    {
      key: "00080050", // 	AccessionNumber
      value: accessionNumber || "",
    },
    {
      key: "00080090", // ReferringPhysiciansName
      value: referringPhysicianName || "",
    },
    {
      key: "00080052",
      value: type || "STUDY",
    },
    {
      key: "00080050",
      value: "",
    },
    {
      key: "00080054",
      value: retrieveAE || "",
    },
    {
      key: "00201209",
      value: numOfSeriesRelated || "",
    },
    {
      key: "00104000",
      value: "",
    },
    {
      key: "00080032", // Acquisition Time
      value: acquisitionTime || "",
    },
    {
      key: "00080022", // Acquisition Date	
      value: acquisitionDate || "",
    },
    {
      key: "00200012", // Acquisition Number
      value: acquisitionNumber || "",
    },
  ];
};

export const findDataFromNode = async (gateway_id, node_id, tags) => {
  try {
    const response = await serverAPIAxios.post(
      `/dicom-node/query`,
      {
        gateway_id,
        node_id,
        tags,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error findDataFromNode", error);
    throw error;
  }
};

export const findDataSeriesFromNode = async (gateway_id, node_id, tags) => {
  try {
    const response = await serverAPIAxios.post(
      `/dicom-node/query/series`,
      {
        gateway_id,
        node_id,
        tags,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error findDataSeriesFromNode", error);
    throw error;
  }
};

export const deleteDicomNode = async (gateway_id, node_id) => {
  try {
    const deleteBody = {
      gateway_id,
      node_id,
    };
    const response = await serverAPIAxios.delete(`/dicom-node`, {
      data: deleteBody,
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error deleteDicomNode", error);
    throw error;
  }
};

export const updateDicomNode = async (data) => {
  try {
    const response = await serverAPIAxios.put(`/dicom-node`, data, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error updateDicomNode", error);
    throw error;
  }
};

export const createDicomNode = async (data) => {
  try {
    const response = await serverAPIAxios.post(`/dicom-node`, data, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error createDicomNode", error);
    throw error;
  }
};

export const getDicomNodeStatus = async (gateway_id, node_id) => {
  try {
    const response = await serverAPIAxios.get(
      `/dicom-node/${gateway_id}/${node_id}/status`,
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getDicomNodeStatus", error);
    throw error;
  }
};
