import { GatewaySelectionTable } from "../../components/Gateway/Gateway";
import { DicomNodeSelectionTable } from "../../components/DicomNode/DicomNodeSelection";
import { ArrowDownOutlined } from '@ant-design/icons'
import { Button } from 'antd'
export const DestinationGateway = ({
  selectedDestinationGatewayId,
  selectedDestinationDicomNodeId,
  gatewayData,
  dicomNode,
  label,
  onShowMoreClick,
}) => {
  const currentSelectedDicom = dicomNode && dicomNode.find((dicomNode) => dicomNode.id === selectedDestinationDicomNodeId)
  const currentSelectedGateway = gatewayData && gatewayData.find((gateway) => gateway.id === selectedDestinationGatewayId)

  const dicomNodes = currentSelectedDicom ? [currentSelectedDicom] : [];
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex flex-col">
          <GatewaySelectionTable
            label={label}
            gatewayData={[currentSelectedGateway]}
            selectedGateway={currentSelectedGateway}
          />
          <Button onClick={onShowMoreClick} type="link">Show more destination Gateways</Button>
        </div>

        <div className="w-full flex items-center justify-center">
          <ArrowDownOutlined className="text-xl mb-2.5" />
        </div>
        <div className="flex flex-col">
          <DicomNodeSelectionTable
            selectedGateway={selectedDestinationGatewayId}
            data={dicomNodes}
            selectedDicomeNodeID={selectedDestinationDicomNodeId}
            isLoading={false}
          />
          <Button onClick={onShowMoreClick} type="link">Show more destination DICOM Nodes</Button>
        </div>
      </div>
    </div>
  );
};
