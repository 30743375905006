import { Table } from "antd";
import { useEffect, useState } from "react";
import { StudiesListForExcel } from "../components/Studies/StudiesListForExcel";
import { findDataFromNode, buildQuery } from "../../apis/node";
import { v4 as uuidv4 } from 'uuid';
import { useContext } from 'react'
import { AppContext } from '../../libs/context'

import _ from "lodash";

const columns = [
  {
    title: "AnonymousName",
    dataIndex: "AnonymousName",
    render: (_, record) => {
      return <p>{record.AnonymousName || "N/A"}</p>;
    },
  },
  {
    title: "AccessionNumber",
    dataIndex: "AccessionNumber",
    render: (_, record) => {
      return <p>{record.AccessionNumber || "N/A"}</p>;
    },
  },
  {
    title: "PatientID",
    dataIndex: "PatientID",
    render: (_, record) => {
      return <p>{record.PatientID || "N/A"}</p>;
    },
  },
  {
    title: "Modality",
    dataIndex: "Modality",
    render: (_, record) => {
      return <p>{record.Modality || "N/A"}</p>;
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    render: (_, record) => {
      return <p>{record.Status || "Waiting"}</p>;
    },
  },
];

const generateRowKey = (record) => {
  const key =
    _.get(record, "AccessionNumber") +
    _.get(record, "PatientID") +
    _.get(record, "Modality") +
    _.get(record, "Status") +
    uuidv4();
  return key;
};

const buildAnonymousNameMap = ({
  patientID,
  accessionNumber,
  anonymousName,
  setAnonymousNameMap
}) => {
  // if study id not inside map then send without PHI will use this instead
  // only applicaabale for batch transfering
  const key = patientID || accessionNumber;
  if (anonymousName) {
    setAnonymousNameMap(key, anonymousName)
  }
}

export const ExcelList = () => {
  const { store } = useContext(AppContext)
  const { toggleKeyExpand, expandedRowKeys, setStudyData, currentStudyData, excelTableData, setExcelTableData, setSelectedSeries } = store;
  const { excelRawData, selectedSourceDicomNodeId, selectedSourceGatewayId } = store;
  const { setAnonymousNameMap } = store;
  const hasSelectedGatewayAndDicomeNodeID = selectedSourceGatewayId && selectedSourceDicomNodeId;

  useEffect(() => {
    if (excelRawData && hasSelectedGatewayAndDicomeNodeID) {
      if (currentStudyData && Object.keys(currentStudyData).length > 0) {
        return;
      }

      const updatedData = excelRawData.map((record) => {
        const key = generateRowKey(record);
        return {
          ...record,
          key,
        };
      });

      setExcelTableData(updatedData);
      updatedData.forEach(async (data) => {
        setTimeout(async () => {
          const patientID = _.get(data, "PatientID", null);
          const modality = _.get(data, "Modality", null);
          const accessionNumber = _.get(data, "AccessionNumber", null);
          if (!patientID && !modality && !accessionNumber) {
            return;
          }

          const anonymousName = _.get(data, "AnonymousName", null);
          buildAnonymousNameMap({
            patientID,
            accessionNumber,
            anonymousName,
            setAnonymousNameMap
          });

          const queryBuilder = buildQuery({
            patientID,
            modality,
            accessionNumber,
          });

          const studyResponseData = await findDataFromNode(
            selectedSourceGatewayId,
            selectedSourceDicomNodeId,
            queryBuilder
          );

          if (studyResponseData.data) {
            setStudyData({
              [patientID]: studyResponseData.data,
            });
          }

          toggleKeyExpand(data.key);
        }, 500);
      });
    }
  }, [excelRawData, hasSelectedGatewayAndDicomeNodeID])

  const handleExpand = async (expanded, record) => {
    toggleKeyExpand(record.key);
  };

  useEffect(() => {
    const updateStatusStudyData = () => {
      if (!currentStudyData || Object.keys(currentStudyData).length === 0) {
        return;
      }

      // Turn waiting to OK status
      const newDataWithStatus = excelTableData.map(obj => {
        const hasStudyData = obj.PatientID && currentStudyData[obj.PatientID] && currentStudyData[obj.PatientID].length !== 0;
        return hasStudyData ? { ...obj, Status: "OK" } : obj;
      });

      setExcelTableData(newDataWithStatus);
    };

    updateStatusStudyData();
  }, [currentStudyData]);


  useEffect(() => {
    // This function updates the expanded row keys if there are any rows with status "OK"
    const updateExpandedRowKeysBasedOnStatus = () => {
      // Find the first row with status "OK" and non-null study data
      const rowToExpand = excelTableData.find(row => row.Status === "OK" && currentStudyData[row.PatientID] && currentStudyData[row.PatientID].length > 0);
      if (rowToExpand) {
        toggleKeyExpand(rowToExpand.key);
      }
    };

    updateExpandedRowKeysBasedOnStatus();

  }, [excelTableData]);


  const [localSelectedStudies, setLocalSelectedSeries] = useState({});
  const onStudiesChange = (studies, key) => {
    localSelectedStudies[key] = studies;
    setLocalSelectedSeries(localSelectedStudies);
    const selectedSeries = Object.values(localSelectedStudies).flat();
    setSelectedSeries(selectedSeries);
  };

  return (
    <div>
      <Table
        rowKey="key"
        expandable={{
          expandedRowRender: (record) => {
            if (!currentStudyData[record.PatientID] || currentStudyData[record.PatientID].length === 0) {
              return <></>;
            }

            return (
              <StudiesListForExcel
                data={currentStudyData[record.PatientID]}
                gatewayID={selectedSourceGatewayId}
                nodeID={selectedSourceDicomNodeId}
                onSelect={onStudiesChange}
                uniqueKey={record.PatientID}
              />
            );
          },
          expandedRowKeys: expandedRowKeys,
          onExpand: handleExpand,
        }}
        className="border-solid border-[1px]"
        columns={columns}
        dataSource={excelTableData}
        pagination={true}
      />
    </div>

  );
};
