import { getToken } from "../libs/jwt";
import axios from "axios";
import { appConfig } from "../config/config";

export const serverAPIAxios = axios.create({
  baseURL: appConfig.apiHost,
});

export const _generateAuthoriseHeader = () => {
  const authToken = getToken();
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authToken,
  };
};

export const _generateAuthoriseHeaderForm = () => {
  const authToken = getToken();
  return {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + authToken,
  };
};
