import { Table, Button, Popconfirm, Tooltip } from "antd";
import { useState } from "react";
import moment from "moment";

export const JobsTable = ({ data, onJobCancel, onViewLog }) => {
  const [selectedCBKeys, setSelectedCBKeys] = useState(new Set());

  const addSelectedCBKey = (key) => {
    setSelectedCBKeys((prev) => new Set(prev).add(key));
  };

  const deleteSelectedCBKey = (key) => {
    setSelectedCBKeys((prev) => {
      const newSet = new Set(prev);
      newSet.delete(key);
      return newSet;
    });
  };

  const clearAllCBKeys = () => {
    setSelectedCBKeys(new Set());
  };

  const addKeyRecursively = (node) => {
    addSelectedCBKey(node.id);
    if (node.children) {
      node.children.forEach((child) => addKeyRecursively(child));
    }
  };

  const removeKeyRecursively = (node) => {
    deleteSelectedCBKey(node.id);
    if (node.children) {
      node.children.forEach((child) => removeKeyRecursively(child));
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => {
        return (
          <Tooltip placement="left" title={record.name}>
            <p className='truncate w-[400px]'>{record.name}</p>
          </Tooltip>
        )
      },
    },
    {
      title: "Logs",
      dataIndex: "status",
      render: (text, record) => {
        return (
          <Button
            className="bg-blue-500 hover:bg-blue-700"
            type="primary"
            onClick={() => {
              onViewLog(record.id)
            }}
          >
            View Logs
          </Button>
        )

      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        return <p>{record.status || "Waiting"}</p>;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      className: "w-[150px]",
      render: (text, record) => {
        return <p>{record.createdAt}</p>;
      },
    },
  ];

  const formattedData = data
    .map(item => ({
      ...item,
      createdAt: item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss") : "N/A",
    }))
    .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));

  const selectedRowKeys = Array.from(selectedCBKeys);
  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      if (selected) {
        addKeyRecursively(record);
      } else {
        removeKeyRecursively(record);
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        changeRows.forEach((item) => addKeyRecursively(item));
      } else {
        changeRows.forEach((item) => removeKeyRecursively(item));
      }
    },
  };

  const handleJobCancel = () => {
    if (selectedRowKeys.length === 0) {
      return;
    }

    onJobCancel(selectedRowKeys);
    clearAllCBKeys();
  }

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const handleTableChange = (page, pageSize) => {
    setPagination({
      current: page,
      pageSize: pageSize,
    });
  }

  return (
    <>
      <div className="w-full flex flex-row-reverse">
        {onJobCancel && <div className="flex flex-row">
          <p className="mr-2.5 mt-1.5"> {selectedRowKeys.length} jobs selected</p>
          <Popconfirm
            title="Are you sure you want to cancel all jobs?"
            onConfirm={handleJobCancel}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ className: "bg-blue-500 hover:bg-blue-600" }}
          >
            <Button disabled={selectedRowKeys.length === 0} type="primary" danger>
              Cancel
            </Button>
          </Popconfirm>
        </div>}
      </div>
      <Table
        rowSelection={onJobCancel ? rowSelection : null}
        className="w-full mt-2"
        columns={columns}
        rowKey="id"
        dataSource={formattedData}
        pagination={{
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
          onChange: (page, pageSize) => handleTableChange(page, pageSize),
        }}
      />
    </>
  );
};