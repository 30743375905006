import { GatewaySelectionTable } from "../../components/Gateway/Gateway";
import { DicomNodeSelectionTable } from "../../components/DicomNode/DicomNodeSelection";
import { AppContext } from '../../../libs/context';
import { useContext } from "react";
import { MODAL_MANGE_DICOM_NODE } from '../../constants/modals';
import { ArrowUpOutlined } from '@ant-design/icons'
import { Button } from "antd";
export const SourceGatewayDicomNodeSelection = ({
  onGatewaySelect,
  currentSelectedGatewayId,
  selectedSourceDicomNodeId,
  gatewayData,
  dicomNode,
  label,
  onShowMoreClick
}) => {
  const { _, setCurrentModal, store } = useContext(AppContext);

  const currentSelectedDicom = dicomNode && dicomNode.find((dicomNode) => dicomNode.id === selectedSourceDicomNodeId)
  const currentSelectedGateway = gatewayData && gatewayData.find((gateway) => gateway.id === currentSelectedGatewayId)
  const onGatewayChange = (selectedGateway) => onGatewaySelect(selectedGateway);
  const onManageDicomNodeClick = () => {
    setCurrentModal({
      modal: MODAL_MANGE_DICOM_NODE,
      data: {
        gateway_id: currentSelectedGatewayId,
        dicomNode
      },
    })
  };

  const dicomNodes = currentSelectedDicom ? [currentSelectedDicom] : [];
  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-col">
        <GatewaySelectionTable
          showKillJob={true}
          label={label}
          allowShowEditGateway={true}
          onManageDicomNodeClick={onManageDicomNodeClick}
          onGatewayChange={onGatewayChange}
          gatewayData={[currentSelectedGateway]}
          selectedGateway={currentSelectedGateway}
        />
        <Button onClick={onShowMoreClick} type="link">Show more source Gateways</Button>
      </div>

      <div className="w-full flex items-center justify-center">
        <ArrowUpOutlined className="text-xl mt-2.5 mb-2.5" />
      </div>
      <div className="flex flex-col">
        <DicomNodeSelectionTable
          selectedGateway={currentSelectedGateway}
          data={dicomNodes}
        />
        <Button onClick={onShowMoreClick} type="link">Show more source DICOM Nodes</Button>
      </div>

    </div>
  );
};
