import { Layout, Divider, Button, Table, message, Spin, Menu, Dropdown } from "antd";
import { PlusOutlined, CheckOutlined, SettingOutlined } from '@ant-design/icons';
import { Form } from "antd";
import { EditDicomNodeModal } from "./Modal/EditDicomModal";
import { EditAccessModal } from "./Modal/EditAccessModal";
import { CreateAccessModal } from "./Modal/CreateNewAccessModal";
import {
    listAllGatewayManageByAdmin,
    listAllDicomeNodeByGatewayId,
    listAllPermissionByDicomNodeId,
    deleteAccessPermission,
    createAccessPermission,
    editGateway,
    setUserAsAdminGateway,
    setUserAsUserGateway,
} from "../../apis/admin";
import lodash from "lodash";
import { useEffect, useState } from "react";
import { getTokenData } from "../../libs/jwt";
import { MODAL_EDIT_DICOM_NODE, MODAL_MANGE_DICOM_NODE, MODAL_EDIT_GATEWAY } from '../../../src/pages/constants/modals';
import { useContext } from "react";
import { AppContext } from '../../libs/context';
import { isListenerOfflineStatus, isOfflineStatus } from '../../libs/status'
import AuthorisedAccess from "./AuthorisedAccess";
import { fetchGatewaysStatus } from '../../hooks/GatewayStatusCustomHook';

export const AdminControl = () => {
    const [selectedDicomeNodeData, setSelectedDicomeNodeData] = useState(null);
    const [selectedGateway, setSelectedGateway] = useState(null);
    const currentUser = getTokenData();
    const onEditDicomNodeClick = (data) => {
        const dicomNodeData = {
            ...data,
            gateway_id: selectedGateway.id,
            node_id: data.id,
        }
        setCurrentModal({
            modal: MODAL_EDIT_DICOM_NODE,
            data: dicomNodeData
        });
    }

    const [isOpenEditAccessClick, setisOpenEditAccessClick] = useState(false);
    const [isOpenCreateAccessClick, setisOpenCreateAccessClick] = useState(false);
    const onNewAccessClick = () => {
        setisOpenCreateAccessClick(!isOpenCreateAccessClick);
    };

    const onCreateAccessClose = () => {
        setisOpenCreateAccessClick(false);
    };

    const onEditDicomAccessClose = () => {
        setisOpenEditAccessClick(false);
    };

    const onEditAccessClick = (data) => {
        setSelectedDicomeNodeData(data);
        setisOpenEditAccessClick(true);
    };

    const buildDicomObject = (node) => {
        const accessibilities = node.accessibilities.map((accessibility) => {
            return {
                name: accessibility.name,
                id: accessibility.id
            }
        });

        return {
            id: node.id, // Assuming you want to use this as the unique key for each row
            name: node.name,
            ip: node.ip,
            retrieve: node.retrieve,
            transferSyntax: node.transferSyntax,
            aet: node.aet,
            port: node.port,
            accessibilities,
        };
    };

    const [gateways, setGateways] = useState([]);
    useEffect(() => {
        const loadGateway = async () => {
            try {
                const response = await listAllGatewayManageByAdmin();
                setGateways(response.data);
            } catch (e) {
                console.error(e);
            }
        };

        loadGateway();
    }, []);

    const onGatewaySelect = (gatewayId, record) => {
        setSelectedGateway(record[0]);
    };

    const onDicomSelect = (dicomNode, record) => {
        setSelectedDicomeNodeData(record[0]);
    }

    const [isLoadingDicomNode, setLoadingDicomNode] = useState(false);
    const [dicomNodes, setDicomeNode] = useState([]);

    const loadDicomNode = async () => {
        if (!selectedGateway) return;
        if (isLoadingDicomNode) return;
        setLoadingDicomNode(true);
        try {
            if(!selectedGateway || !selectedGateway.id) return;
            const response = await listAllDicomeNodeByGatewayId(selectedGateway.id);
            if (!response.data) {
                return;
            }
            const tableData = response.data.map((node) => buildDicomObject(node));
            setDicomeNode(tableData);
            setSelectedDicomeNodeData(tableData[0])
        } catch (e) {
            console.error(e);
        } finally {
            setLoadingDicomNode(false);
        }
    };

    useEffect(() => {
        loadDicomNode();
    }, [selectedGateway]);

    const [nodePermissions, setNodePermissions] = useState([]);
    const loadPermissionByDicomNode = async () => {
        try {
            if (!selectedDicomeNodeData || !selectedDicomeNodeData.id) return;
            const permissionsData = await listAllPermissionByDicomNodeId(selectedDicomeNodeData.id);
            const computePermissionData = mergeRecordsByUserId(permissionsData, currentUser)
            setNodePermissions(computePermissionData);
        } catch (error) {
            console.error("Failed to fetch permissions:", error);
        }
    };

    useEffect(() => {
        loadPermissionByDicomNode();
    }, [selectedDicomeNodeData])

    const mergeRecordsByUserId = (records, currentUser) => {
        const merged = {};
        records.data.filter((data) => {
            const isShouldSkip = data.user.auth0_user_id === currentUser.user_id;
            return !isShouldSkip;
        }).forEach((record) => {
            const userId = record.user.id;
            if (!merged[userId]) {
                merged[userId] = { ...record.user, permissions: [] };
            }

            merged[userId].permissions.push({
                id: record.id,
                permission: record.permission,
            });
        });

        return Object.values(merged);
    };

    const [isCreatingPermission, setIsCreatingPermission] = useState(false);
    const onCreateAccessSubmit = async (data) => {
        try {
            if (isCreatingPermission) {
                return;
            }

            setIsCreatingPermission(true);
            for (const permissionId of data.permissionIds) {
                await createAccessPermission(
                    data.nodeId,
                    data.gatewayId,
                    permissionId,
                    data.email
                );
            }

            setisOpenCreateAccessClick(false);
            message.success("Access Created");
            setTimeout(() => {
                window.location.reload(true);
                setIsCreatingPermission(false);
            }, 1000);
        } catch (e) {
            setIsCreatingPermission(false);
            console.error(e);
            if (e.response && e.response.data) {
                message.error(e.response.data.message);
            }
        }
    };

    const onEditAccessSubmit = async (data) => {
        const { submittedPermissionIds, permissionsToBeDeleted, email } = data;

        try {
            // Handle permission deletions
            if (permissionsToBeDeleted && permissionsToBeDeleted.length > 0) {
                await Promise.all(
                    permissionsToBeDeleted.map((id) => deleteAccessPermission(id))
                );
            }

            // Handle permission creations
            if (submittedPermissionIds && submittedPermissionIds.length > 0) {
                await Promise.all(
                    submittedPermissionIds.map((id) =>
                        createAccessPermission(
                            selectedDicomeNodeData.id,
                            selectedGateway,
                            id,
                            email
                        )
                    )
                );
            }

            // If everything is successful, show a success message
            message.success("Access permissions updated successfully!");
            onEditAccessClose();
            setTimeout(() => {
                window.location.reload(true);
            }, 1000);
        } catch (error) {
            // Handle any errors that occur during the deletion or creation processes
            console.error("Error updating access permissions:", error);
            message.error("An error occurred while updating access permissions.");
        }
    };

    const [isEditingDicom, setIsEditingGateway] = useState(false);
    const onEditDicomNodeSubmit = async (data) => {
        try {
            if (isEditingDicom) {
                return;
            }
            setIsEditingGateway(true);

            const request = {
                gateway_id: selectedGateway.id,
                node_id: selectedDicomeNodeData.id,
                ip: data.ip,
                name: data.name,
                transferSyntax: data.transferSyntax,
                accessibility_ids: data.accessibility_ids,
                port: data.port,
                aet: data.aet,
                retrieve: data.retrieve,
            };

            await editGateway(request);
            message.success("Gateway Updated");
            setTimeout(() => {
                window.location.reload(true);
                setIsEditingGateway(false);
            }, 1000);
        } catch (e) {
            setIsEditingGateway(false);
            console.error(e);
            if (e.response && e.response.data) {
                message.error(e.response.data.message);
            }
        }
    };

    const [isEditAccessModalOpen, setEditAccessModalOpen] = useState(false);
    const [editAccessData, setEditAccessData] = useState(null);

    const onEditAccessClose = () => {
        setEditAccessModalOpen(false);
    };

    const [selectGatewayForm] = Form.useForm();

    useEffect(() => {
        const selectDefaultWay = () => {
            if (gateways && gateways.length !== 0) {
                const initialValues = {
                    label: gateways[0].name,
                    value: gateways[0].id,
                };
                selectGatewayForm.setFieldsValue({ gateway: initialValues });
                setSelectedGateway(gateways[0]);
            }
        };

        selectDefaultWay();
    }, [gateways]);

    const isAdminRole = (isGatewayAdmin) => isGatewayAdmin;

    const onSetAdminGateway = async (record) => {
        try {
            await setUserAsAdminGateway(record.auth0_user_id, selectedGateway);
            message.success("Set user to admin success");
            setTimeout(() => {
                window.location.reload(true);
            }, 1000);
        } catch (e) {
            message.error("Failed to set user admin gateway");
            console.error(e);
        }
    };

    const onSetUserGateway = async (record) => {
        try {
            await setUserAsUserGateway(record.auth0_user_id, selectedGateway);
            message.success("Set user to admin success");
        } catch (e) {
            message.error("Failed to set user admin gateway");
            console.error(e);
        }
    };

    const { setCurrentModal } = useContext(AppContext);
    const [gatewayWithStatus, setGatewayWithStatus] = useState([]);
    useEffect(() => {
        const fetchGatewayWithStatus = async () => {
            if (!gateways || gateways.length === 0) return;
            const gatewayWithStatus = await fetchGatewaysStatus(gateways);
            setGatewayWithStatus(gatewayWithStatus);
        }

        fetchGatewayWithStatus();
    }, [gateways]);

    const onEditGatewayClick = async () => {
        setCurrentModal({
            modal: MODAL_EDIT_GATEWAY,
            data: gatewayWithStatus,
        });
    }

    const onManageDicomNodeClick = async () => {
        setCurrentModal({
            modal: MODAL_MANGE_DICOM_NODE,
            data: {
                gateway_id: selectedGateway.id,
                dicomNode: dicomNodes
            },
        });
    }

    const gatewayColumns = [
        { title: 'id', dataIndex: 'id', key: 'id', hidden: true },
        { title: 'Gateway Name', dataIndex: 'name', key: 'name' },
        {
            title: 'Gateway Status', dataIndex: 'status', key: 'status', render: (_, record) => {
                if (isOfflineStatus(record)) {
                    return <p className='text-red-500'>{record.status}</p>
                }

                return <p>{record.status}</p>
            }
        },
        {
            title: 'Listener IP', dataIndex: 'listener_ip', key: 'listener_ip', render: (_, record) => {
                return <p className="w-[100px]">{record.listener_ip}</p>
            }
        },
        { title: 'Listener Status', dataIndex: 'listener_status', key: 'listener_status', render:(_, record) => {
            if (isListenerOfflineStatus(record)) {
                return <p className='text-red-500'>{record.listener_status}</p>
            }

            return <p>{record.listener_status}</p>
        } },
    ];

    const dicomNodeColumns = [
        {
            title: "Name",
            dataIndex: "name",
            className: "text-xs",
            render: (_, record) => <p>{record.name}</p>,
        },
        {
            title: "AET",
            dataIndex: "aet",
            className: "text-xs",
        },
        {
            title: "Address",
            dataIndex: "ip",
            className: "text-xs",
            render: (_, record) => (
                <>
                    {record.ip || ""}
                    <br></br>
                    {record.port || ""}
                </>
            ),
        },
        {
            title: "Accessibility",
            dataIndex: "accessibility",
            render: (_, record) => {
                if (!record.accessibilities || record.accessibilities.length === 0) {
                    return <p>N/A</p>
                }
                const accessibilityNames = record.accessibilities.map((accessibility) => accessibility.name).join(", ");
                return <p>{accessibilityNames}</p>;
            },
        },
        {
            title: "Action",
            render: (_, record) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="edit" onClick={() => onEditDicomNodeClick(record)}>
                            Edit
                        </Menu.Item>
                        <Menu.Item key="public-private" onClick={() => onEditAccessClick(record)}>
                            Public/Private
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown overlay={menu} trigger={['click']}>
                        <Button>
                            Actions
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <Layout className="bg-white flex-row flex-col w-full">
            {isOpenEditAccessClick && (
                <EditDicomNodeModal
                    data={selectedDicomeNodeData}
                    onClose={onEditDicomAccessClose}
                    onSubmit={onEditDicomNodeSubmit}
                />
            )}
            {isOpenCreateAccessClick && (
                <CreateAccessModal
                    onClose={onCreateAccessClose}
                    onSubmit={onCreateAccessSubmit}
                />
            )}
            {isEditAccessModalOpen && (
                <EditAccessModal
                    data={editAccessData}
                    onClose={onEditAccessClose}
                    onSubmit={onEditAccessSubmit}
                />
            )}
            <div className="p-4 max-w-screen-2xl mx-0 center mx-auto w-[1300px]">
                <h1 className="text-2xl font-bold text-center mt-7">
                    Admin Control
                </h1>
                <p className="text-sm text-gray-600 mt-2.5 mb-2.5 text-center">
                    Note : Admin control Gateways and DICOM Nodes, enable you to create, update, delete user access, restart Gateways if they fail.
                </p>
                <Divider />
                <div className="w-full">
                    <Form form={selectGatewayForm} name="selectGateway">
                        <div className="flex mb-3.5">
                            <div
                                className="mr-2.5 w-1/2"
                            >
                                <div className="flex justify-center items-center  mb-2.5">
                                    <h2 className="text-center text-xl font-semibold">Gateways</h2>
                                    <Button icon={<SettingOutlined />} onClick={onEditGatewayClick} className="ml-2.5" type="default">
                                        Manage Gateways
                                    </Button>
                                </div>
                                <Table
                                    className="border border-solid"
                                    columns={gatewayColumns}
                                    dataSource={gatewayWithStatus}
                                    rowKey={"id"}
                                    rowSelection={{
                                        type: 'radio',
                                        onChange: onGatewaySelect,
                                        selectedRowKeys: selectedGateway ? [selectedGateway.id] : [],
                                    }}
                                    pagination={false}
                                />
                            </div>

                            <div
                                className="w-1/2"
                            >
                                <div className="flex justify-center items-center  mb-2.5">
                                    <h2 className="text-center text-xl font-semibold">DICOM Nodes</h2>
                                    <Button icon={<SettingOutlined />} onClick={onManageDicomNodeClick} className="ml-2.5" type="default">
                                        Manage DICOM Nodes
                                    </Button>
                                </div>
                                <Table
                                    className="border border-solid"
                                    dataSource={dicomNodes}
                                    columns={dicomNodeColumns}
                                    rowKey={"id"}
                                    rowSelection={{
                                        type: 'radio',
                                        onChange: onDicomSelect,
                                        selectedRowKeys: selectedDicomeNodeData ? [selectedDicomeNodeData.id] : [],
                                    }}
                                    pagination={false}
                                />
                            </div>

                        </div>
                    </Form>

                    <Spin spinning={isLoadingDicomNode}>
                        <Button icon={<PlusOutlined />} onClick={onNewAccessClick} className="mb-2.5" type="default">
                            Give Access To User
                        </Button>
                        <Table
                            rowKey="id"
                            columns={[
                                { title: "User Email", dataIndex: ["email"] },
                                {
                                    title: "QUERY",
                                    render: (_, record) => {
                                        const isQueryPermissionExist = lodash.some(
                                            record.permissions,
                                            {
                                                permission: "QUERY",
                                            }
                                        );
                                        if (isQueryPermissionExist) {
                                            return <CheckOutlined />;
                                        }
                                        return <></>;
                                    },
                                },
                                {
                                    title: "RETRIEVE",
                                    render: (_, record) => {
                                        const isRetrievePermissionExist = lodash.some(
                                            record.permissions,
                                            {
                                                permission: "RETRIEVE",
                                            }
                                        );
                                        if (isRetrievePermissionExist) {
                                            return <CheckOutlined />;
                                        }
                                        return <></>;
                                    },
                                },
                                {
                                    title: "WRITE",
                                    render: (_, record) => {
                                        const isRetrievePermissionExist = lodash.some(
                                            record.permissions,
                                            {
                                                permission: "WRITE",
                                            }
                                        );
                                        if (isRetrievePermissionExist) {
                                            return <CheckOutlined />;
                                        }
                                        return <></>;
                                    },
                                },
                                {
                                    title: "SEND WITH PHI",
                                    render: (_, record) => {
                                        const isRetrievePermissionExist = lodash.some(
                                            record.permissions,
                                            {
                                                permission: "SEND_WITH_PHI",
                                            }
                                        );
                                        if (isRetrievePermissionExist) {
                                            return <CheckOutlined />;
                                        }
                                        return <></>;
                                    },
                                },
                                {
                                    title: "Action",
                                    render: (_, record) => {
                                        const isAdmin = !isAdminRole(
                                            lodash.get(record, "isGatewayAdmin")
                                        )
                                        return (
                                            <div>
                                                <Button
                                                    onClick={() => {
                                                        setEditAccessData(record);
                                                        setSelectedDicomeNodeData(selectedDicomeNodeData);
                                                        setEditAccessModalOpen(true);
                                                    }}
                                                    danger
                                                >
                                                    Edit
                                                </Button>
                                                {isAdmin && (
                                                    <Button
                                                        className="ml-5"
                                                        onClick={() => {
                                                            onSetAdminGateway(record);
                                                        }}
                                                    >
                                                        Set as Admin
                                                    </Button>
                                                )}

                                                {!isAdmin && (
                                                    <Button
                                                        className="ml-5"
                                                        onClick={() => {
                                                            onSetUserGateway(record);
                                                        }}
                                                    >
                                                        Set as User
                                                    </Button>
                                                )}
                                            </div>
                                        );
                                    },
                                },
                            ]}
                            dataSource={nodePermissions || []}
                            pagination={true}
                        />
                    </Spin>

                    <h1 className="text-2xl font-bold text-center mt-7">
                        Authorization Requests
                    </h1>
                    <p className="text-sm text-gray-600 mt-2.5 mb-2.5 text-center">
                        Note : Users request access to your Gateway and DICOM Nodes. Admin can approve or delete the requests.
                    </p>

                    <Divider />
                    <AuthorisedAccess />
                </div>
            </div>
        </Layout>
    );
}