import _ from 'lodash';

export const ImageSharingLoadingJobs = (set, get) => ({
    isLoadingSourceGateway: false,
    isLoadingDestinationGateway: false,
    isLoadingSourceDicomNodes: false,
    isLoadingDestinationDicomNodes: false,
    isSearchingResults: false,

    setLoadingSourceGateway: (isLoading) => set({ isLoadingSourceGateway: isLoading }),
    setLoadingDestinationGateway: (isLoading) => set({ isLoadingDestinationGateway: isLoading }),
    setLoadingSourceDicomNodes: (isLoading) => set({ isLoadingSourceDicomNodes: isLoading }),
    setLoadingDestinationDicomNodes: (isLoading) => set({ isLoadingDestinationDicomNodes: isLoading }),
    setSearchingResults: (isSearching) => set({ isSearchingResults: isSearching }),

    getLoadingSourceGateway: () => get().isLoadingSourceGateway,
    getLoadingDestinationGateway: () => get().isLoadingDestinationGateway,
    getLoadingSourceDicomNodes: () => get().isLoadingSourceDicomNodes,
    getLoadingDestinationDicomNodes: () => get().isLoadingDestinationDicomNodes,
    getSearchingResults: () => get().isSearchingResults,
})