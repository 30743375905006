export const GatewayStore = (set) => ({
        gatewayData: [],
        selectedDestinationGatewayId: null,
        selectedSourceGateway: null,
        selectedSourceGatewayId: null,
        destinationGateways: [],
        gatewaySourceListener: [],
        gatewayDestinationListener: [],
        setGatewaySourceListener: (gatewaySourceListener) => set({ gatewaySourceListener }),
        setSelectedDestinationGateway: (gateway) => set({ selectedDestinationGatewayId: gateway }),
        setSelectedSourceGateway: (gateway) => set({ selectedSourceGateway: gateway }),
        setGatewayDestinationListener: (gatewayDestinationListener) => set({ gatewayDestinationListener }),
        setSourceGatewayId: (id) => set({ selectedSourceGatewayId: id }),
        setGatewayData: (data) => set({ gatewayData: data }),
        setDestinationGateways: (destinationGateways) => set({ destinationGateways }),
})