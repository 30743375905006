import { Table, Button, Spin, message } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { cancelRunningJobByGatewayId } from '../../../apis/job-status'
import { MODAL_EDIT_GATEWAY } from '../../../../src/pages/constants/modals';
import { useContext } from "react";
import { AppContext } from '../../../libs/context';
import { isOfflineStatus, isListenerOfflineStatus } from '../../../libs/status'
export const GatewaySelectionTable = ({
  gatewayData = [],
  isLoading = false,
  onGatewayChange,
  onManageDicomNodeClick,
  selectedGateway,
  allowShowEditGateway,
  showKillJob,
  label,
}) => {
  const columns = [
    {
      title: "Gateway Name",
      dataIndex: "name",
      className: "text-xs",
      render: (_, record) => {
        return <p className="max-w-[90px]">{record.name}</p>;
      },
    },
    {
      title: "Gateway Status",
      dataIndex: "status",
      className: "w-[100px] text-xs",
      render: (_, record) => {
        if (isOfflineStatus(record)) {
          return <p className='text-red-500'>{record.status}</p>
        }

        return <p>{record.status}</p>
      }
    },
    {
      title: "Listener IP",
      dataIndex: "listener_ip",
      className: "text-xs",
      render: (_, record) => {
        return <p className="max-w-[150px]">{record.listener_ip}</p>;
      },
    },
    {
      title: "Listener Status",
      dataIndex: "listener_status",
      className: "w-[90px] text-xs",
      render: (_, record) => {
        if (isListenerOfflineStatus(record)) {
          return <p className='text-red-500'>{record.listener_status}</p>
        }

        return <p>{record.listener_status}</p>
      }
    },
    {
      title: "Type",
      dataIndex: "type",
      className: "w-[100px] text-xs",
      render: (_, record) => {
        return <p>{record.type || 'N/A'}</p>;
      },
    },
  ];

  const filteredAdminEditGatewayOnly = gatewayData && gatewayData.filter((gateway) => {
    return gateway.type === "admin";
  });

  const onKillAllJobClick = async () => {
    message.info('Clearing all jobs...', 2);
    try {
      await cancelRunningJobByGatewayId(selectedGateway.id);
      message.info('All Jobs Cancelled', 2);
    } catch (error) {
      console.error(error);
      message.error(error.message);
    }
  }


  const allowToKillJob = selectedGateway && gatewayData && gatewayData.filter((gateway) => {
    return gateway.type === "admin" && gateway.id === selectedGateway.id && gateway.status.indexOf("online") >= 0 && gateway.id !== 'cloud-pacs';
  })

  const { setCurrentModal } = useContext(AppContext);
  const onEditGatewayModalClick = () => {
    setCurrentModal({
      modal: MODAL_EDIT_GATEWAY,
      data: filteredAdminEditGatewayOnly,
    });
  }


  return (
    <Spin spinning={isLoading}>
      <div className="flex-col text-center w-full mb-3">
        <div className="flex flex-row justify-center items-center text-center">
          <div className="flex flex-row w-full mb-4">
            <label className='text-xl font-bold mr-2.5'>{label}</label>
            {allowShowEditGateway && filteredAdminEditGatewayOnly.length !== 0 && gatewayData && gatewayData.length !== 0 && (
              <Button
                icon={<SettingOutlined />}
                data-testid={`edit-gateway-button`}
                onClick={onEditGatewayModalClick}
              >
                Gateway
              </Button>
            )}

            {onManageDicomNodeClick && selectedGateway &&
              selectedGateway.type === "admin" && (
                <Button icon={<SettingOutlined />} className="ml-2.5" onClick={onManageDicomNodeClick} >
                  DICOM Node
                </Button>
              )}

            {showKillJob && allowToKillJob && allowToKillJob.length !== 0 && <Button
              className="ml-2"
              data-testid={`edit-gateway-button`}
              onClick={onKillAllJobClick}
              danger
            >
              Clear All Jobs
            </Button>}
          </div>
        </div>
        <Table
          className="border-r-[1px] border-t-[1px] border-l-[1px]"
          columns={columns}
          dataSource={gatewayData}
          rowKey={(record) => `${record.id}-${record.listener_ip}-gateway`}
          pagination={false}
        />
      </div>
    </Spin>
  );
};
