import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Button,
  Table,
  Spin,
  InputNumber,
  Input,
  Typography,
  Popconfirm,
  message,
} from "antd";
import moment from "moment";
import { deleteStudy, editStudy, getTags } from "../../apis/apis";
import { AnonymizeColumnsToBeReplaced } from "../constants/columns";

const convertToFilterTags = (listData) => {
  if (!listData || listData.length === 0) {
    return [];
  }

  return listData.map((data) => {
    return {
      text: data.Name,
      value: data.Tag,
    };
  });
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const EditDicomPageModal = ({
  currentEditStudyData,
  isOpen,
  handleCancel,
}) => {
  const [form] = Form.useForm();
  const [tags, setTags] = useState({});
  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      if (!currentEditStudyData.id) {
        return;
      }
      try {
        const response = await getTags(currentEditStudyData.id);
        if (!response.data) {
          return;
        }
        setTags(response.data);
      } catch (e) {}
    };

    fetchTags();
  }, [currentEditStudyData.id]);

  const deleteTag = async (record) => {
    try {
      const row = await form.validateFields();
      if (!row || !row.Value || row.Value.trim().length === 0) {
        return;
      }

      await editStudy(currentEditStudyData.id, {
        Remove: [record.Name],
      });

      setTimeout(() => {
        handleCancel();
        window.location.reload();
      }, 1000);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const [messageApi, messageContextHolder] = message.useMessage();
  const [isSaving, setIsSaving] = useState(false);
  const save = async (record) => {
    try {
      messageApi.open({
        key: "update-dicom-tag",
        type: 'loading',
        content: 'Updating dicom tag...',
        duration: 0,
      });

      const row = await form.validateFields();
      const type = record.Name;
      let newEditBody = {};
      if (type === "StudyDate" && moment.isMoment(row.Value)) {
        newEditBody.StudyDate = row.Value.format("YYYY-MM-DD");
      }

      newEditBody[type] = row.Value || "";
      if (isSaving) return;
      setIsSaving(true);
      await editStudy(currentEditStudyData.id, {
        Replace: newEditBody,
      });

      messageApi.open({
        key: "update-dicom-tag",
        type: 'success',
        content: 'Update dicom tag success',
        duration: 2,
      });

      setTimeout(() => {
        handleCancel();
        window.location.reload();
        setIsSaving(false);
      }, 1000);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
      messageApi.open({
        key: "update-dicom-tag",
        type: 'error',
        content: 'Failed to update dicom tag',
        duration: 2,
      });
    }
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: "Do you want to permanently delete?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        try {
          deleteStudy(currentEditStudyData.id, currentEditStudyData.parentStudy)
        } catch (e) {
          console.log("Error ", e);
        }
        handleCancel(); // Optionally close the modal after deletion
        setTimeout(() => {
          window.location.reload(true);
        }, 1500)
      },
      onCancel() {
        console.log("Deletion cancelled");
      },
    });
  };

  const modalFooter = (
    <div>
      <Button key="back" className="mr-2" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        className="bg-red-600"
        key="delete"
        type="primary"
        danger
        onClick={showDeleteConfirm}
      >
        Delete this study
      </Button>
    </div>
  );

  useEffect(() => {
    const totalKeys = Object.keys(tags).length;
    if (totalKeys > 0) {
      let newTagList = [];
      Object.keys(tags).map((key) => {
        if (typeof tags[key]["Value"] !== "object") {
          newTagList = [
            ...newTagList,
            {
              ...tags[key],
              Tag: key,
              key: key,
            },
          ];
        }
      });
      setTagList(newTagList);
    }
  }, [tags]);

  const isEditing = (record) => record.key === editingKey;
  const [editingKey, setEditingKey] = useState("");
  const edit = (record) => {
    form.setFieldsValue(record);
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const columns = [
    {
      title: "Field Name",
      dataIndex: "Name",
      key: "Name",
      className: "text-sm p-2",
      filterSearch: true,
      filters: convertToFilterTags(tagList),
      onFilter: (value, record) => {
        return record.Tag.indexOf(value) >= 0;
      },
    },
    {
      title: "Tag",
      key: "Tag",
      dataIndex: "Tag",
      className: "text-sm p-2",
    },
    {
      title: "Content",
      key: "Value",
      dataIndex: "Value",
      editable: true,
      render: (value, record) => {
        return <p className="w-[150px]">{value}</p>;
      },
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      className: "text-sm p-2",
      render: (value, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record, value)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Typography.Text
              className="text-red-500 cursor-pointer"
              style={{ marginRight: 8 }}
            >
              <Popconfirm
                title="Do you want to delete?"
                description={() => {
                  return (
                    <>
                      These fields will be deleted <b>{record.Name}</b>
                    </>
                  );
                }}
                okButtonProps={{ className: "bg-blue-600" }}
                onConfirm={() => deleteTag(record)}
              >
                Delete
              </Popconfirm>
            </Typography.Text>
            <Popconfirm
              okButtonProps={{ className: "bg-blue-600" }}
              title="Do you want to cancel?"
              onConfirm={cancel}
            >
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const [formAddNewTag] = Form.useForm();
  const addNewDicomTagSubmit = async () => {
    try {
      const formData = await formAddNewTag.validateFields();
      await editStudy(currentEditStudyData.id, {
        Replace: {
          [formData.field_name]: formData.field_value,
        },
      });

      message.success("Add new dicom tag success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      console.error(e);
    }
  };

  const addNewDicomTagCancel = () => {
    formAddNewTag.resetFields();
    setShowNewTag(!isShowAddNewTag);
  };

  const [isShowAddNewTag, setShowNewTag] = useState(false);

  const runAnonymize = async () => {
    const replaceHeaderTagBody = {};
    AnonymizeColumnsToBeReplaced.forEach((header) => {
      replaceHeaderTagBody[header] = "";
    });

    try {
      await editStudy(currentEditStudyData.id, {
        Replace: replaceHeaderTagBody,
      });

      message.success("Anonymize success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      className=""
      title="Edit DICOM"
      open={isOpen}
      closable={true}
      footer={modalFooter}
      width={700}
      onCancel={() => {
        handleCancel();
        form.resetFields();
      }}
    >
      <div className="pb-2.5">
        <Button
          onClick={() => {
            formAddNewTag.resetFields();
            setShowNewTag(!isShowAddNewTag);
          }}
          className="bg-blue-600"
          type="primary"
        >
          Add new tag
        </Button>
        {isShowAddNewTag && (
          <div>
            <Form form={formAddNewTag} layout="vertical" className="pt-2.5">
              <Form.Item
                rules={[{ required: true, message: "Please input field name" }]}
                name="field_name"
              >
                <Input placeholder="Field Name" className="w-[150px]"></Input>
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Please input field value" },
                ]}
                name="field_value"
              >
                <Input placeholder="Value" className="w-[150px]"></Input>
              </Form.Item>
            </Form>

            <Button
              onClick={addNewDicomTagSubmit}
              className="bg-blue-600"
              type="primary"
            >
              Save
            </Button>

            <Button onClick={addNewDicomTagCancel}>Cancel</Button>
          </div>
        )}
      </div>

      <div className="pb-2.5">
        <Popconfirm
          title={`Anonymization deletes below tags : `}
          description={
            <ul className="max-w-[200px]">
              {AnonymizeColumnsToBeReplaced.map((header) => {
                return <li>{header}</li>;
              })}
            </ul>
          }
          okButtonProps={{ className: "bg-blue-600" }}
          onConfirm={runAnonymize}
        >
          <Button className="bg-blue-600" type="primary">
            Anonymize function
          </Button>
        </Popconfirm>
      </div>

      <Form form={form} layout="vertical" name="editDicomForm">
        {!tagList || (tagList.length === 0 && <Spin />)}
        {tagList && tagList.length > 0 && (
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            className="w-full"
            dataSource={tagList}
            columns={mergedColumns}
          />
        )}
        {messageContextHolder}
      </Form>
    </Modal>
  );
};
