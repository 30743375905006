import { Typography, Table, Spin } from "antd";
import { isOfflineStatus } from '../../../libs/status'

export const DicomNodeSelectionTable = ({
  data,
  isLoading = false,
}) => {
  const columns = [
    {
      title: "DICOM Node",
      dataIndex: "name",
      className: "text-xs",
      render: (_, record) => {
        return <p>{record.name}</p>;
      },
    },
    {
      title: "AET",
      dataIndex: "aet",
      className: "text-xs",
    },
    {
      title: "Address",
      dataIndex: "ip",
      className: "text-xs",
      render: (_, record) => {
        return (
          <>
            {`${record.ip}`}
            <br></br>
            {record.port}
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "text-xs",
      render: (_, record) => {
        if (isOfflineStatus(record)) {
          return <p className='text-red-500'>{record.status}</p>
        }
        return <p>{record.status}</p>;
      }
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <div className="flex-col text-center w-full">
        <Table
          className="border-solid border-r-[1px] border-t-[1px] border-l-[1px] w-full"
          columns={columns}
          dataSource={data || []}
          rowKey="id"
          pagination={false}
        />
      </div>
    </Spin>
  );
};
