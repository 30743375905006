import React, { useEffect, useState } from "react";
import { Modal, Form, Select, Button, Spin } from "antd";
import { listPermissionOptions } from "../../../apis/admin"; // Assuming this API call returns accessibility options as well
import { PRIVATE_ACCESSIBILITY } from '../../constants/accessibility'
export const EditDicomNodeModal = ({ data, onClose, onSubmit }) => {
  const [form] = Form.useForm();
  const [accessibilityOptions, setAccessibilityOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAccessibilityOptions = async () => {
      setLoading(true);
      try {
        const response = await listPermissionOptions(); // Assuming this fetches accessibility options
        setAccessibilityOptions(response.accessibility); // Adjust according to actual response structure
      } catch (error) {
        console.error("Failed to fetch accessibility options:", error);
      }
      setLoading(false);
    };

    fetchAccessibilityOptions();
  }, []);

  const onModalClose = () => {
    if (onClose) onClose();
  };

  const onFinish = (values) => {
    const { accessibility_ids } = values;
    const updatedData = {
      ...data,
      accessibility_ids,
    };

    if (onSubmit) {
      return onSubmit(updatedData);
    }
  };

  const [selectedItems, setSelectedItems] = useState([]);
  const selectOptionsValue = accessibilityOptions.map((data) => {
    return {
      label: data.name,
      value: data.id,
      disabled: selectedItems && selectedItems.includes(PRIVATE_ACCESSIBILITY) && data.id !== PRIVATE_ACCESSIBILITY
    };
  });

  useEffect(() => {
    if (accessibilityOptions.length > 0 && data.accessibilities && data.accessibilities.length > 0) {
      const accessibilitiesIds = data.accessibilities.map((accessibility) => {
        return accessibility.id;
      });

      form.setFieldsValue({
        accessibility_ids: accessibilitiesIds,
      });
    }
  }, [accessibilityOptions, data.accessibilities, form]);

  const handleChange = (values) => {
    setSelectedItems(values);
    if (values.includes(PRIVATE_ACCESSIBILITY)) {
      form.setFieldsValue({
        accessibility_ids: [PRIVATE_ACCESSIBILITY],
      });
    } else {
      const noPrivatePermission = values.filter((permission) => {
        return permission !== PRIVATE_ACCESSIBILITY;
      });
      form.setFieldsValue({
        accessibility_ids: noPrivatePermission,
      });
    }
  }

  return (
    <Modal
      title="Edit DICOM Accessibility"
      open={true}
      onCancel={onModalClose}
      footer={[
        <Button key="back" onClick={onModalClose}>
          Cancel
        </Button>,
        <Button
          className="bg-blue-500"
          key="submit"
          type="primary"
          onClick={() => form.submit()}
        >
          Save Changes
        </Button>,
      ]}
      className="sm:max-w-7xl md:max-w-7xl"
      width={800}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          name="editAccessModal"
        >
          <Form.Item
            name="accessibility_ids"
            label="Select DICOM Accessibility"
            rules={[
              {
                required: true,
                message: "Please select an accessibility option!",
              },
            ]}
          >
            <Select 
              options={selectOptionsValue}
              mode="multiple"
              optionFilterProp="children"
              onChange={handleChange}
              placeholder="Select DICOM accessibility" 
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
