import { Layout, Divider, Button, Select, message, Spin, Typography } from "antd";
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Input, Form } from "antd";
import { SearchOutlined, CheckOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { EditDicomNodeModal } from "./Modal/EditDicomModal";
import { EditAccessModal } from "./Modal/EditAccessModal";
import { CreateAccessModal } from "./Modal/CreateNewAccessModal";
import {
  listAllGatewayManageByAdmin,
  listAllDicomeNodeByGatewayId,
  listAllPermissionByDicomNodeId,
  listAllDicomeNodeByGatewayIdWithEmailFilter,
  deleteAccessPermission,
  createAccessPermission,
  editGateway,
  setUserAsAdminGateway,
  setUserAsUserGateway,
} from "../../apis/admin";
import { MyAccess } from './MyAccess'
import lodash from "lodash";
import { useEffect, useState } from "react";
import { getTokenData } from "../../libs/jwt";
import { MODAL_EDIT_DICOM_NODE, MODAL_RAISE_ACCESS, MODAL_MANGE_DICOM_NODE, MODAL_EDIT_GATEWAY } from '../../../src/pages/constants/modals';
import { useContext } from "react";
import { AppContext } from '../../libs/context';
import AuthorisedAccess from "./AuthorisedAccess";
import { fetchGatewaysStatus } from '../../hooks/GatewayStatusCustomHook';

const { Title } = Typography;

const dicomManageColumns = [
  {
    title: "ID",
    hidden: true,
    dataIndex: "id",
  },
  {
    title: "DICOM Node",
    dataIndex: "name",
  },
  {
    title: "IP",
    dataIndex: "ip",
  },
  {
    title: "Port",
    dataIndex: "port",
  },
  {
    title: "Retrieve",
    dataIndex: "retrieve",
  },
  {
    title: "Accessibility",
    dataIndex: "accessibility",
    render: (_, record) => {
      if (!record.accessibilities || record.accessibilities.length === 0) {
        return <p>N/A</p>
      }
      const accessibilityNames = record.accessibilities.map((accessibility) => accessibility.name).join(", ");
      return <p>{accessibilityNames}</p>;
    },
  }
]

export const AccessControl = () => {
  const [selectedDicomeNodeData, setSelectedDicomeNodeData] = useState(null);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const currentUser = getTokenData();
  const onEditDicomNodeClick = (data) => {
    const dicomNodeData = {
      ...data,
      gateway_id: selectedGateway,
      node_id: data.id,
    }
    setCurrentModal({
      modal: MODAL_EDIT_DICOM_NODE,
      data: dicomNodeData
    });
  }

  const columns = [
    ...dicomManageColumns,
    {
      title: "Action",
      render: (_, record) => {
        return (
          <div className="flex">
            <Button onClick={() => {
              onEditDicomNodeClick(record);
            }}>
              Edit
            </Button>
            <Button
              className="ml-2.5"
              onClick={() => {
                onEditAccessClick(record);
              }}
            >
              Public/Private
            </Button>
          </div>
        );
      },
    },
  ];

  const [isOpenEditAccessClick, setisOpenEditAccessClick] = useState(false);
  const [isOpenCreateAccessClick, setisOpenCreateAccessClick] = useState(false);
  const onNewAccessClick = () => {
    setisOpenCreateAccessClick(!isOpenCreateAccessClick);
  };

  const onCreateAccessClose = () => {
    setisOpenCreateAccessClick(false);
  };

  const onEditDicomAccessClose = () => {
    setisOpenEditAccessClick(false);
  };

  const onEditAccessClick = (data) => {
    setSelectedDicomeNodeData(data);
    setisOpenEditAccessClick(true);
  };

  const buildDicomObject = (node) => {
    const accessibilities = node.accessibilities.map((accessibility) => {
      return {
        name: accessibility.name,
        id: accessibility.id
      }
    });

    return {
      id: node.id, // Assuming you want to use this as the unique key for each row
      name: node.name,
      ip: node.ip,
      retrieve: node.retrieve,
      transferSyntax: node.transferSyntax,
      aet: node.aet,
      port: node.port,
      accessibilities,
    };
  };

  const [gateways, setGateways] = useState([]);
  useEffect(() => {
    const loadGateway = async () => {
      try {
        const response = await listAllGatewayManageByAdmin();
        setGateways(response.data);
      } catch (e) {
        console.error(e);
      }
    };

    loadGateway();
  }, []);

  const onGatewaySelect = (gatewayId) => {
    setSelectedGateway(gatewayId);
  };

  const [isLoadingDicomNode, setLoadingDicomNode] = useState(false);
  const [dicomNodes, setDicomeNode] = useState([]);

  const loadDicomNode = async () => {
    if (!selectedGateway) return;
    if (isLoadingDicomNode) return;
    setLoadingDicomNode(true);
    try {
      const response = await listAllDicomeNodeByGatewayId(selectedGateway);
      if (!response.data) {
        return;
      }
      const tableData = response.data.map((node) => buildDicomObject(node));
      setDicomeNode(tableData);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingDicomNode(false);
    }
  };

  useEffect(() => {
    loadDicomNode();
  }, [selectedGateway]);

  const [nodePermissions, setNodePermissions] = useState({});
  const handleExpand = async (expanded, record) => {
    if (expanded && !nodePermissions[record.id]) {
      try {
        const permissionsData = await listAllPermissionByDicomNodeId(record.id);
        setNodePermissions({
          ...nodePermissions,
          [record.id]: mergeRecordsByUserId(permissionsData, currentUser),
        });
      } catch (error) {
        console.error("Failed to fetch permissions:", error);
      }
    }
  };

  const mergeRecordsByUserId = (records, currentUser) => {
    const merged = {};
    records.data.filter((data) => {
      const isShouldSkip = data.user.auth0_user_id === currentUser.user_id;
      return !isShouldSkip;
    }).forEach((record) => {
      const userId = record.user.id;
      if (!merged[userId]) {
        merged[userId] = { ...record.user, permissions: [] };
      }

      merged[userId].permissions.push({
        id: record.id,
        permission: record.permission,
      });
    });

    return Object.values(merged);
  };

  const [isCreatingPermission, setIsCreatingPermission] = useState(false);
  const onCreateAccessSubmit = async (data) => {
    try {
      if (isCreatingPermission) {
        return;
      }

      setIsCreatingPermission(true);
      for (const permissionId of data.permissionIds) {
        await createAccessPermission(
          data.nodeId,
          data.gatewayId,
          permissionId,
          data.email
        );
      }

      setisOpenCreateAccessClick(false);
      message.success("Access Created");
      setTimeout(() => {
        window.location.reload(true);
        setIsCreatingPermission(false);
      }, 1000);
    } catch (e) {
      setIsCreatingPermission(false);
      console.error(e);
      if (e.response && e.response.data) {
        message.error(e.response.data.message);
      }
    }
  };

  const onEditAccessSubmit = async (data) => {
    const { submittedPermissionIds, permissionsToBeDeleted, email } = data;

    try {
      // Handle permission deletions
      if (permissionsToBeDeleted && permissionsToBeDeleted.length > 0) {
        await Promise.all(
          permissionsToBeDeleted.map((id) => deleteAccessPermission(id))
        );
      }

      // Handle permission creations
      if (submittedPermissionIds && submittedPermissionIds.length > 0) {
        await Promise.all(
          submittedPermissionIds.map((id) =>
            createAccessPermission(
              selectedDicomeNodeData.id,
              selectedGateway,
              id,
              email
            )
          )
        );
      }

      // If everything is successful, show a success message
      message.success("Access permissions updated successfully!");
      onEditAccessClose();
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (error) {
      // Handle any errors that occur during the deletion or creation processes
      console.error("Error updating access permissions:", error);
      message.error("An error occurred while updating access permissions.");
    }
  };

  const [isEditingDicom, setIsEditingGateway] = useState(false);
  const onEditDicomNodeSubmit = async (data) => {
    try {
      if (isEditingDicom) {
        return;
      }
      setIsEditingGateway(true);

      const request = {
        gateway_id: selectedGateway,
        node_id: selectedDicomeNodeData.id,
        ip: data.ip,
        name: data.name,
        transferSyntax: data.transferSyntax,
        accessibility_ids: data.accessibility_ids,
        port: data.port,
        aet: data.aet,
        retrieve: data.retrieve,
      };

      await editGateway(request);
      message.success("Gateway Updated");
      setTimeout(() => {
        window.location.reload(true);
        setIsEditingGateway(false);
      }, 1000);
    } catch (e) {
      setIsEditingGateway(false);
      console.error(e);
      if (e.response && e.response.data) {
        message.error(e.response.data.message);
      }
    }
  };

  const [isEditAccessModalOpen, setEditAccessModalOpen] = useState(false);
  const [editAccessData, setEditAccessData] = useState(null);

  const onEditAccessClose = () => {
    setEditAccessModalOpen(false);
  };

  const [selectGatewayForm] = Form.useForm();

  useEffect(() => {
    const selectDefaultWay = () => {
      if (gateways && gateways.length !== 0) {
        const initialValues = {
          label: gateways[0].name,
          value: gateways[0].id,
        };
        selectGatewayForm.setFieldsValue({ gateway: initialValues });
        setSelectedGateway(gateways[0].id);
      }
    };

    selectDefaultWay();
  }, [gateways]);

  const [isSearching, setIsSearching] = useState(false);
  const onClickSearch = async () => {
    const values = selectGatewayForm.getFieldValue("keyword");
    if (!values || values.length <= 0) {
      return loadDicomNode();
    }

    if (isSearching) return;
    setIsSearching(true);
    const gateway_id = selectedGateway;
    const email = values.trim();
    try {
      const resp = await listAllDicomeNodeByGatewayIdWithEmailFilter(
        gateway_id,
        email
      );
      if (resp.data) {
        const tableData = resp.data.map((node) => buildDicomObject(node));
        setDicomeNode(tableData);
      }
    } catch (e) {
      console.error(e);
      if (e.response && e.response.data) {
        message.error(e.response.data.message);
      }
    } finally {
      setIsSearching(false);
    }
  };

  const isAdminRole = (isGatewayAdmin) => isGatewayAdmin;

  const onSetAdminGateway = async (record) => {
    try {
      await setUserAsAdminGateway(record.auth0_user_id, selectedGateway);
      message.success("Set user to admin success");
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (e) {
      message.error("Failed to set user admin gateway");
      console.error(e);
    }
  };

  const onSetUserGateway = async (record) => {
    try {
      await setUserAsUserGateway(record.auth0_user_id, selectedGateway);
      message.success("Set user to admin success");
    } catch (e) {
      message.error("Failed to set user admin gateway");
      console.error(e);
    }
  };

  const { setCurrentModal } = useContext(AppContext);
  const onModalListofPermissionClick = () => {
    setCurrentModal({
      modal: MODAL_RAISE_ACCESS,
      data: null,
    });
  }

  const [gatewayWithStatus, setGatewayWithStatus] = useState([]);
  useEffect(() => {
    const fetchGatewayWithStatus = async () => {
      if (!gateways || gateways.length === 0) return;
      const gatewayWithStatus = await fetchGatewaysStatus(gateways);
      setGatewayWithStatus(gatewayWithStatus);
    }

    fetchGatewayWithStatus();
  }, [gateways]);

  const onEditGatewayClick = async () => {
    setCurrentModal({
      modal: MODAL_EDIT_GATEWAY,
      data: gatewayWithStatus,
    });
  }

  const onManageDicomNodeClick = async () => {
    setCurrentModal({
      modal: MODAL_MANGE_DICOM_NODE,
      data: {
        gateway_id: selectedGateway,
        dicomNode: dicomNodes
      },
    });
  }

  return (
    <Layout className="bg-white flex-row flex-col w-full">
      {isOpenEditAccessClick && (
        <EditDicomNodeModal
          data={selectedDicomeNodeData}
          onClose={onEditDicomAccessClose}
          onSubmit={onEditDicomNodeSubmit}
        />
      )}
      {isOpenCreateAccessClick && (
        <CreateAccessModal
          onClose={onCreateAccessClose}
          onSubmit={onCreateAccessSubmit}
        />
      )}
      {isEditAccessModalOpen && (
        <EditAccessModal
          data={editAccessData}
          onClose={onEditAccessClose}
          onSubmit={onEditAccessSubmit}
        />
      )}
      <div className="p-4 max-w-screen-2xl mx-0 center mx-auto w-[900px]">
        <h1 className="text-2xl font-bold text-center mt-7">
          Your accessible Gateways and DICOM Nodes
        </h1>
        <Divider />
        <Button onClick={onModalListofPermissionClick} className="mt-2.5 mb-2.5" icon={<PlusOutlined />}>Raise a New Access to Admin</Button>
        <MyAccess />
      </div>
    </Layout>
  );
}