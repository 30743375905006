import { Layout } from "antd";
import NavLogged from "../components/Navigations/NavLogged";
import Footer from "../components/Footer/Footer";
import { useState } from "react";
import { ChangeInformationModal } from "./modal/ChangeInformationModal"
import { ChangePasswordModal } from "./modal/ChangePasswordModal"
import { RequestAccessModal } from "./modal/RequestAccess";
import { EditDicomNodeModal } from "./modal/EditDicomNode";
import { ManageJobs } from './modal/ManageJobsModal/ManageJobs'
import { EditGatewayModal } from "./modal/EditGateway/EditGatewayModal";
import { useEffect } from 'react'
import SelectGatewayAndDicomModal from './modal/SelectDicomAndGatewayModal'
import { ManageNodeModal } from "./modal/ManageNode";
import AIRuleModal from './modal/AIRuleModal'
import { MODAL_RAISE_ACCESS, MODAL_EDIT_DICOM_NODE, MODAL_EDIT_GATEWAY, MODAL_MANGE_DICOM_NODE, MODAL_SHOW_GATEWAY_DICOM_MANAGE, MODAL_MANAGE_JOBS_MODAL, MODAL_EDIT_RULE_CLICK } from '../../src/pages/constants/modals';
import { updateUserPassword, updateUserInformation } from '../apis/apis'
import { getTokenData } from "../libs/jwt";
import {
  requestPermission,
} from "../apis/admin";
import { message } from "antd";
import { useContext } from 'react';
import { AppContext } from '../libs/context';
import { fetchGatewaysStatus } from '../hooks/GatewayStatusCustomHook';
import { useNavigate } from 'react-router-dom';

import {
  CLOUD_PACS_MODE,
  LISTENER_ONLINE_STATUS,
} from "../pages/constants/dicomNode";
const CloudPacs = {
  id: CLOUD_PACS_MODE,
  name: "Cloud PACS System",
  status: LISTENER_ONLINE_STATUS,
  listener_ip: "N/A",
  listener_status: "N/A",
  gateway_ip: "N/A",
  type: 'N/A'
};

const DashboardLayout = ({ children, onClose }) => {
  const navigate = useNavigate();
  const tokenData = getTokenData();
  const { currentModal, setCurrentModal, store } = useContext(AppContext);

  const replaceRoute = (url) => {
    navigate(url, { replace: true });
  };

  const onRequestSubmit = async (data) => {
    try {
      await requestPermission({
        permission_ids: data.permission_ids,
        node_id: data.node_id,
        gateway_id: data.gateway_id,
      });
      message.success("Request permission success");
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (e) {
      message.error("Failed to request permission check error");
      console.error(e);
    }
  };

  const [isPasswordShowModal, setIsPasswordModalVisible] = useState(false);
  const [isChangePersonalModal, setIsPersonalModalVisible] = useState(false);

  const onChangePersonalInformationClick = () => {
    setIsPersonalModalVisible(!isChangePersonalModal)
  }

  const onChangePasswordClick = () => {
    setIsPasswordModalVisible(!isPasswordShowModal);
  }

  const onAdminControlClick = () => {
    replaceRoute('/dashboard/admin-control');
  }

  const onSubmitChangeInformation = async (data) => {
    try {
      const resp = await updateUserInformation(data);
      if (resp && resp.data) {
        message.success("Change information success");
      }
    } catch (e) {
      console.error(e);
    }
  }

  const onSubmitChangePassword = async (data) => {
    try {
      if (!data.confirmPassword) return;
      await updateUserPassword(data.confirmPassword);
      message.success("Change password success");
    } catch (e) {
      console.error(e);
      message.error("Failed to change password");
    }
  }

  const onModalClose = () => {
    setCurrentModal({
      modal: null,
      data: null,
    });
  }


  const refetchCurrentGatewayStatus = async (gateways) => {
    try {
      if (!gateways || gateways.length === 0) return;
      const gatewayWithStatus = await fetchGatewaysStatus(gateways);
      return gatewayWithStatus;
    } catch (e) {
      console.error(e);
      return [];
    }
  }


  const {
    setGatewayData,
    setSelectedDestinationGateway,
    setSourceGatewayId,
    setSourceDicomeNodeID,
    setDestinationGateways,
    selectedSourceGatewayId,
    selectedDestinationGatewayId,
    setDestinationDicome } = store;

  const { destinationDicomNodes, sourceDicomNodes } = store;
  const { gatewayData, destinationGateways } = store;
  const { selectedDestinationDicomId, selectedSourceDicomNodeId } = store
  const { getLoadingSourceGateway, getLoadingDestinationGateway, getLoadingSourceDicomNodes, getLoadingDestinationDicomNodes, setDestinationDicomNodes, setSourceDicomNodes } = store;
  const { modelsData } = store;
  const isSourceGatewayLoading = getLoadingSourceGateway();
  const isDestinationGatewayLoading = getLoadingDestinationGateway();

  const isSourceDicomLoading = getLoadingSourceDicomNodes();
  const isDestinationDicomLoading = getLoadingDestinationDicomNodes();

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.clear();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <Layout className="bg-white">
      {isChangePersonalModal && (
        <ChangeInformationModal onSubmit={onSubmitChangeInformation} onClose={onChangePersonalInformationClick} isVisible={isChangePersonalModal} />
      )}

      {isPasswordShowModal && (
        <ChangePasswordModal onSubmit={onSubmitChangePassword} onClose={onChangePasswordClick} isVisible={isPasswordShowModal} />
      )}
      {currentModal.modal === MODAL_RAISE_ACCESS && (
        <RequestAccessModal
          onClose={onModalClose}
          onSubmit={onRequestSubmit}
          data={{
            email: tokenData.email || "",
          }}
        />
      )}

      {currentModal.modal === MODAL_EDIT_GATEWAY && (
        <EditGatewayModal
          handleClose={onModalClose}
          data={currentModal.data}
        />
      )}

      {currentModal.modal === MODAL_MANGE_DICOM_NODE && (
        <ManageNodeModal
          handleClose={onModalClose}
          gateway_id={currentModal.data.gateway_id}
          data={currentModal.data.dicomNode}
        />
      )}

      {currentModal.modal === MODAL_SHOW_GATEWAY_DICOM_MANAGE && (
        <SelectGatewayAndDicomModal
          onCancel={onModalClose}
          isLoadingGateway={currentModal.data.type === 'source' ? isSourceGatewayLoading : isDestinationGatewayLoading}
          isLoadingDicom={currentModal.data.type === 'source' ? isSourceDicomLoading : isDestinationDicomLoading}
          selectedGatewayId={currentModal.data.type === 'source' ? selectedSourceGatewayId : selectedDestinationGatewayId}
          selectedDicomNodeId={currentModal.data.type === 'source' ? selectedSourceDicomNodeId : selectedDestinationDicomId}
          gatewayData={currentModal.data.type === 'source' ? gatewayData : [CloudPacs, ...(destinationGateways || [])]}
          dicomNodes={currentModal.data.type === 'source' ? sourceDicomNodes : destinationDicomNodes}
          onGatewaySelect={(selectedGateway) => {
            if (currentModal.data.type === 'source') {
              if (selectedGateway.id !== selectedSourceGatewayId) {
                setSourceDicomeNodeID(null);
                setSourceDicomNodes([]);
              }
              setSourceGatewayId(selectedGateway.id)
            } else {
              if (selectedGateway.id !== selectedDestinationGatewayId) {
                setDestinationDicome(null);
                setDestinationDicomNodes([]);
              }
              setSelectedDestinationGateway(selectedGateway.id)
            }

          }}
          onSearchGatewayResult={async (gateways) => {
            if (!gateways || gateways.length === 0) {
              return;
            }

            const gatewayStatus = await refetchCurrentGatewayStatus(gateways);
            if (currentModal.data.source === 'source') {
              setGatewayData(gatewayStatus)
            } else {
              setDestinationGateways(gatewayStatus)
            }
          }}
          onSubmit={(data) => {
            const selectedDicomNodeAndGateway = data.gatewayId && data.dicomNodeId;
            if (!selectedDicomNodeAndGateway && data.gatewayId !== CloudPacs.id) {
              message.error("You must select Gateway and DICOM node")
              return;
            }

            if (currentModal.data.type === 'source') {
              setSourceGatewayId(data.gatewayId)
              setSourceDicomeNodeID(data.dicomNodeId);
            } else {
              setSelectedDestinationGateway(data.gatewayId);
              if (data.gatewayId === CloudPacs.id) {
                setDestinationDicome(null);
              } else {
                setDestinationDicome(data.dicomNodeId);
              }
            }

            onModalClose();
          }}
        />
      )}

      {currentModal.modal === MODAL_EDIT_DICOM_NODE && <EditDicomNodeModal data={currentModal.data} onClose={onModalClose} />}

      {currentModal.modal === MODAL_MANAGE_JOBS_MODAL && <ManageJobs onClose={onModalClose} />}

      {currentModal.modal === MODAL_EDIT_RULE_CLICK && <AIRuleModal selectedGatewayId={selectedSourceGatewayId} modelsData={modelsData} data={currentModal.data} onClose={onModalClose} />}


      <NavLogged
        onAdminControlClick={onAdminControlClick}
        onChangePasswordClick={onChangePasswordClick}
        onChangePersonalInformationClick={onChangePersonalInformationClick}
        onLogoutClick={() => {
          localStorage.clear();
          window.location.reload(true);
        }}
      />
      <Layout className="bg-white">{children}</Layout>
      <Footer />
    </Layout>
  );
};
export default DashboardLayout;
