import moment from 'moment';
import _ from "lodash";
import { Tooltip } from 'antd';
import { v4 as uuidv4 } from 'uuid';

export const studyColumns = [
    {
        title: "Study Instance UID",
        hidden: true,
        dataIndex: "studyInstanceUID",
    },
    {
        title: "Series Instance ID",
        dataIndex: "seriesUID",
        hidden: true,
    },
    {
        title: "Patient Name",
        dataIndex: "patientName",
        render: (_, record) => {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip placement="left" title={record.patientName}>
                        <p className='truncate max-w-[150px]' style={{ margin: 0 }}>{record.patientName}</p>
                    </Tooltip>
                </div>
            );
        }
    },
    {
        title: "Modality",
        dataIndex: "modality",
    },
    {
        title: "Patient ID",
        dataIndex: "patientID",
        render: (_, record) => {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip placement="left" title={record.patientID}>
                        <p className='truncate max-w-[100px]' style={{ margin: 0 }}>{record.patientID}</p>
                    </Tooltip>
                </div>
            );
        }
    },
    {
        title: "Date of Birth",
        dataIndex: "dateOfBirth",
    },
    {
        title: "Description",
        dataIndex: "description",
        render: (_, record) => {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip placement="left" title={record.description}>
                        <p className='truncate max-w-[150px]' style={{ margin: 0 }}>{record.description}</p>
                    </Tooltip>
                </div>
            );
        }
    },
    {
        title: "Age",
        dataIndex: "age",
    },
    {
        title: "Accession Number",
        dataIndex: "accessionNumber",
    },
    {
        title: "Source",
        dataIndex: "retrieveAE",
    },
    {
        title: "#im",
        dataIndex: "numOfSeriesRelated",
    },
    {
        title: "Acquisition Time",
        dataIndex: "acquisitionTime",
    },
    {
        title: "Date",
        dataIndex: "dateTime",
        render: (_, record) => {
            if (!record.dateTime) return <></>;
            const parsedDatetime = moment(record.dateTime, "YYYYMMDD").format("YYYY-MM-DD");
            return <>{parsedDatetime.indexOf('Invalid date') >= 0 ? '' : parsedDatetime}</>;

        },
    },
];

export const generateRowKey = (record) => {
    const key = record.studyInstanceUID + record.seriesUID;
    return key + uuidv4();
};