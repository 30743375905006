import jwtDecode from "jwt-decode";

export const getToken = () => {
  const token = localStorage.getItem("token");
  try {
    jwtDecode(token);
    return token;
  } catch (error) {
    throw error;
  }
};

export const getTokenData = () => {
  const token = localStorage.getItem("token");
  try {
    return jwtDecode(token);
  } catch (error) {
    throw error;
  }
};

