import logo from "./logo.png";
import { Menu, Tooltip } from "antd";
import {
  BookOutlined,
  UserOutlined,
  FileAddFilled,
  AppstoreOutlined,
  BarChartOutlined,
  CloudDownloadOutlined,
  RobotOutlined
} from "@ant-design/icons";
import { appConfig } from "../../config/config";
import { Link } from 'react-router-dom';
import { Dropdown, Space } from 'antd';
import { DownOutlined, AppleOutlined, WindowsOutlined } from '@ant-design/icons';
import { getTokenData } from "../../libs/jwt";


const appsDropDownMenus = [
  {
    label: (
      <a target="_blank" rel="noopener noreferrer" href={appConfig.macOSLink}>
        <AppleOutlined className="mr-2.5" />
        MacOS
      </a>
    ),
    key: '0',
  },
  {
    label: (
      <a target="_blank" rel="noopener noreferrer" href={appConfig.windowLink}>
        <WindowsOutlined className="mr-2.5" />
        Window
      </a>
    ),
    key: '1',
  },
  {
    label: (
      <a target="_blank" rel="noopener noreferrer" href={appConfig.moreLink}>
        <CloudDownloadOutlined className="mr-2.5" />
        More
      </a>
    ),
    key: '2',
  },
];

const NavLogged = ({
  onLogoutClick,
  onChangePasswordClick,
  onChangePersonalInformationClick,
  onAdminControlClick
}) => {
  const onClick = ({ key }) => {
    if (key === 'logout') {
      return onLogoutClick();
    }

    if (key === 'change-password' && onChangePasswordClick) {
      return onChangePasswordClick();

    }

    if (key === 'change-information' && onChangePersonalInformationClick) {
      return onChangePersonalInformationClick();
    }

    if (key === 'admin-control' && onAdminControlClick) {
      return onAdminControlClick();

    }
  };

  const items = [
    {
      label: 'Change Password',
      key: 'change-password',
    },
    {
      label: 'Change Information',
      key: 'change-information',
    },
    {
      label: 'Admin Control',
      key: 'admin-control',
    },
    {
      label: 'Logout',
      key: 'logout',
    },
  ];

  const loggedToken = getTokenData();

  return (
    <div className="flex-col items-center bg-white">
      <div className="flex p-8 justify-between">
        <div className="flex">
          <img src={logo} alt="Your Logo" className="h-10" />
        </div>
        <div className="flex-grow ml-2">
          <Menu className="w-73" mode="horizontal">
            <Menu.Item key="home" icon={<BookOutlined />}>
              <Link data-testid="nav-image-transfer" to="/dashboard/" replace>
                Image Transfer
              </Link>
            </Menu.Item>
            <Menu.Item key="viewer" icon={<BarChartOutlined />}>
              <Link data-testid="nav-cloud-pacs" to="/dashboard/studies" replace>
                Cloud Pacs
              </Link>
            </Menu.Item>
            <Menu.Item key="batch-transfering" icon={<FileAddFilled />}>
              <Link
                data-testid="nav-batch-transfering"
                to="/dashboard/batch-transfering" replace>
                Batch Transfering
              </Link>
            </Menu.Item>
            <Menu.Item key="dashboard-ai" icon={<RobotOutlined />}>
              <Link
                data-testid="nav-ai"
                to="/dashboard/ai" replace>
                AI
              </Link>
            </Menu.Item>
            <Menu.Item
              data-testid="nav-download-gatewayapp"
              key="download-gateway-app"
              icon={<AppstoreOutlined />}
            >
              <Dropdown menu={{ items: appsDropDownMenus }}>
                <Space>
                  Apps
                </Space>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </div>
        <div className="w-[850px] flex flex-row-reverse">
          <Menu className="w-[800px] flex flex-row-reverse" mode="horizontal">
            <Menu.Item key="menu-dropdown">
              <Tooltip placement="left" title={loggedToken.email}>
                <Dropdown menu={{ items, onClick }}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <p className="truncate w-[100px]">{loggedToken.email}</p>
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </Tooltip>
            </Menu.Item>
            <Menu.Item
              data-testid="nav-admin-access-control"
              key="permission"
              icon={<UserOutlined />}
            >
              <Link to="/dashboard/permission" replace>My Access</Link>
            </Menu.Item>
          </Menu>
        </div>
      </div>
      <div className="border-b border-gray-300"></div>
    </div>
  );
};

export default NavLogged;
