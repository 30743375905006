import { GatewaySelectionTable } from "../../components/Gateway/Gateway";
import { Button } from 'antd'

export const DestinationGatewayCloudPacs = ({
  selectedDestinationGatewayId,
  gatewayData,
  label,
  onShowMoreClick,
}) => {
  const currentSelectedGateway = gatewayData && gatewayData.find((gateway) => gateway.id === selectedDestinationGatewayId)
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex flex-col">
          <GatewaySelectionTable
            label={label}
            gatewayData={[currentSelectedGateway]}
            selectedGateway={currentSelectedGateway}
          />
          <Button onClick={onShowMoreClick} type="link">Show more destination Gateways</Button>
        </div>
      </div>
    </div>
  );
};
